import { LocalDate } from 'src/common/graphql/scalars';
import { WorkflowStateId } from 'src/common/graphql/scalars';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
  /** LocalDate (e.g. 2024-12-12) */
  LocalDate: { input: LocalDate; output: LocalDate };
  /** state id for workflow */
  WorkflowStateId: { input: WorkflowStateId; output: WorkflowStateId };
};

/** AccessPolicy */
export type AccessPolicy = {
  __typename?: 'AccessPolicy';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AddCheckListCustomFieldAttachmentCommandInput = {
  checkListId: Scalars['Int']['input'];
  contentType: Scalars['String']['input'];
  customFieldId: Scalars['Float']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AddCheckListCustomFieldStampCommandInput = {
  checkListId: Scalars['Int']['input'];
  customFieldId: Scalars['Int']['input'];
  stampedAt: Scalars['DateTime']['input'];
};

export type AddPartAssetDependencyInput = {
  assetId: Scalars['Int']['input'];
  partId: Scalars['Int']['input'];
  requiredPartQuantity?: InputMaybe<Scalars['Int']['input']>;
};

export type AddWorkOrderCheckListCommandInput = {
  checkListTemplateId: Scalars['Int']['input'];
  workOrderId: Scalars['Int']['input'];
};

export type AddWorkOrderCustomFieldFilesCommandInput = {
  customFieldId: Scalars['Float']['input'];
  files: Array<AddWorkOrderCustomFieldFilesCommandInputFile>;
  workOrderId: Scalars['Int']['input'];
};

export type AddWorkOrderCustomFieldFilesCommandInputFile = {
  contentType: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AddWorkOrderPartCommandInput = {
  partId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  workOrderId: Scalars['Int']['input'];
};

/** asset  */
export type Asset = {
  __typename?: 'Asset';
  controlNumber?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customFieldDateValues?: Maybe<Array<AssetCustomFieldDateValue>>;
  customFieldDatetimeValues?: Maybe<Array<AssetCustomFieldDatetimeValue>>;
  customFieldFloatValues?: Maybe<Array<AssetCustomFieldFloatValue>>;
  customFieldIntValues?: Maybe<Array<AssetCustomFieldIntValue>>;
  customFieldSelectValues?: Maybe<Array<AssetCustomFieldSelectValue>>;
  customFieldTextValues?: Maybe<Array<AssetCustomFieldTextValue>>;
  description?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<AssetDocument>>;
  groups?: Maybe<Array<Group>>;
  id: Scalars['Float']['output'];
  manufactureDate?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  officeId: Scalars['Float']['output'];
  parentAsset?: Maybe<Asset>;
  parts: Array<PartAssetDependency>;
  qrBarCode?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
  stoppageReasons: Array<StoppageReason>;
  updatedAt: Scalars['DateTime']['output'];
};

/** AssetCustomField */
export type AssetCustomField = {
  __typename?: 'AssetCustomField';
  customFieldFloatConfig?: Maybe<AssetCustomFieldFloatConfig>;
  customFieldIntConfig?: Maybe<AssetCustomFieldIntConfig>;
  customFieldSelectConfig?: Maybe<AssetCustomFieldSelectConfig>;
  defaults: Array<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionLexical?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  label: Scalars['String']['output'];
  officeId?: Maybe<Scalars['Int']['output']>;
  options: Array<AssetCustomFieldOption>;
  type: AssetCustomFieldTypeEnum;
};

/** Assetのカスタムフィールド(Date)の値 */
export type AssetCustomFieldDateValue = {
  __typename?: 'AssetCustomFieldDateValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['DateTime']['output'];
};

export type AssetCustomFieldDateValueInput = {
  customFieldId: Scalars['Float']['input'];
  value: Scalars['DateTime']['input'];
};

/** Assetのカスタムフィールド(Datetime)の値 */
export type AssetCustomFieldDatetimeValue = {
  __typename?: 'AssetCustomFieldDatetimeValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['DateTime']['output'];
};

export type AssetCustomFieldDatetimeValueInput = {
  customFieldId: Scalars['Float']['input'];
  value: Scalars['DateTime']['input'];
};

/** AssetCustomFieldFloatConfig */
export type AssetCustomFieldFloatConfig = {
  __typename?: 'AssetCustomFieldFloatConfig';
  customFieldId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  interval?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

/** 設備のカスタムフィールド(number)の値 */
export type AssetCustomFieldFloatValue = {
  __typename?: 'AssetCustomFieldFloatValue';
  customFieldId: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type AssetCustomFieldFloatValueInput = {
  customFieldId: Scalars['Float']['input'];
  value: Scalars['Float']['input'];
};

/** AssetCustomFieldIntConfig */
export type AssetCustomFieldIntConfig = {
  __typename?: 'AssetCustomFieldIntConfig';
  customFieldId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  interval?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
};

/** 設備のカスタムフィールド(number)の値 */
export type AssetCustomFieldIntValue = {
  __typename?: 'AssetCustomFieldIntValue';
  customFieldId: Scalars['Float']['output'];
  value: Scalars['Int']['output'];
};

export type AssetCustomFieldIntValueInput = {
  customFieldId: Scalars['Float']['input'];
  value: Scalars['Int']['input'];
};

/** AssetCustomFieldOption */
export type AssetCustomFieldOption = {
  __typename?: 'AssetCustomFieldOption';
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

/** AssetCustomFieldSelectConfig */
export type AssetCustomFieldSelectConfig = {
  __typename?: 'AssetCustomFieldSelectConfig';
  customFieldId: Scalars['Int']['output'];
  id: Scalars['Float']['output'];
  multiple?: Maybe<Scalars['Boolean']['output']>;
};

/** Assetのカスタムフィールド(Select)の値 */
export type AssetCustomFieldSelectValue = {
  __typename?: 'AssetCustomFieldSelectValue';
  customFieldId: Scalars['Int']['output'];
  optionId: Scalars['Int']['output'];
};

export type AssetCustomFieldSelectValueInput = {
  customFieldId: Scalars['Int']['input'];
  optionId: Scalars['Int']['input'];
};

/** 設備のカスタムフィールド(テキスト)の値 */
export type AssetCustomFieldTextValue = {
  __typename?: 'AssetCustomFieldTextValue';
  customFieldId: Scalars['Float']['output'];
  value: Scalars['String']['output'];
};

export type AssetCustomFieldTextValueInput = {
  customFieldId: Scalars['Float']['input'];
  value: Scalars['String']['input'];
};

export const AssetCustomFieldTypeEnum = {
  Date: 'date',
  Datetime: 'datetime',
  Float: 'float',
  Int: 'int',
  Select: 'select',
  Text: 'text',
} as const;

export type AssetCustomFieldTypeEnum =
  (typeof AssetCustomFieldTypeEnum)[keyof typeof AssetCustomFieldTypeEnum];
/** AssetのDocumentの値 */
export type AssetDocument = {
  __typename?: 'AssetDocument';
  assetId: Scalars['Float']['output'];
  contentType?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  size?: Maybe<Scalars['Int']['output']>;
  src?: Maybe<Scalars['String']['output']>;
  type: DocumentTypeEnum;
  url?: Maybe<Scalars['String']['output']>;
};

/** AssetHierarchy */
export type AssetHierarchy = {
  __typename?: 'AssetHierarchy';
  label: Scalars['String']['output'];
  level: Scalars['Float']['output'];
};

/** Asset */
export type AssetsWithPagination = {
  __typename?: 'AssetsWithPagination';
  assets: Array<Asset>;
  totalCount: Scalars['Float']['output'];
};

/** message */
export type BaseChatMessage = {
  __typename?: 'BaseChatMessage';
  chatId: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  isEdited: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  /** LexicalでフォーマットされたJsonをStringで返す */
  messageLexical?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: DisplayUser;
  userId: Scalars['String']['output'];
};

/** BaseCountWorkOrderPart */
export type BaseCountWorkOrderPart = {
  __typename?: 'BaseCountWorkOrderPart';
  count: Scalars['Float']['output'];
  currencyCode: CurrencyCodeType;
  distinctCount: Scalars['Float']['output'];
  totalCost: Scalars['Float']['output'];
  totalQuantity: Scalars['Float']['output'];
};

/** Requestに紐づいてるコメント */
export type BaseRequestComment = {
  __typename?: 'BaseRequestComment';
  comment: Scalars['String']['output'];
  /** LexicalでフォーマットされたJsonをStringで返す */
  commentLexical?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  isEdited: Scalars['Boolean']['output'];
  requestId: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: DisplayUser;
};

export const BottomNavigationTypeEnum = {
  Approval: 'approval',
  Asset: 'asset',
  Bot: 'bot',
  Chat: 'chat',
  CheckList: 'checkList',
  Dashboard: 'dashboard',
  Group: 'group',
  Manual: 'manual',
  Part: 'part',
  Product: 'product',
  Report: 'report',
  Request: 'request',
  Schedule: 'schedule',
  Task: 'task',
  User: 'user',
} as const;

export type BottomNavigationTypeEnum =
  (typeof BottomNavigationTypeEnum)[keyof typeof BottomNavigationTypeEnum];
/** chat */
export type Chat = {
  __typename?: 'Chat';
  company: Company;
  companyId: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  /** 最後のメッセージ(plain text) */
  lastMessage?: Maybe<Scalars['String']['output']>;
  lastMessageCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paginatedChatMessages: PaginatedChatMessage;
  unreadMessageCount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  users: Array<ChatUser>;
};

/** chat */
export type ChatPaginatedChatMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** message */
export type ChatMessage = {
  __typename?: 'ChatMessage';
  attachments?: Maybe<Array<ChatMessageAttachment>>;
  chatId: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  isEdited: Scalars['Boolean']['output'];
  mentionIds: Array<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  /** LexicalでフォーマットされたJsonをStringで返す */
  messageLexical?: Maybe<Scalars['String']['output']>;
  replyMessage?: Maybe<BaseChatMessage>;
  replyMessageId?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: DisplayUser;
  userId: Scalars['String']['output'];
};

/** Chat Message Attachment */
export type ChatMessageAttachment = {
  __typename?: 'ChatMessageAttachment';
  contentType: Scalars['String']['output'];
  fileId: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  messageId: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

/** Work order comment attachment */
export type ChatMessageAttachmentInput = {
  contentType: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ChatMessageEdge = {
  __typename?: 'ChatMessageEdge';
  cursor: Scalars['String']['output'];
  node: ChatMessage;
};

/** Chat room subscription event */
export type ChatMessageSubscriptionEvent = {
  __typename?: 'ChatMessageSubscriptionEvent';
  chat?: Maybe<Chat>;
  chatId: Scalars['Float']['output'];
  eventType: Scalars['String']['output'];
  message?: Maybe<ChatMessage>;
  messageId: Scalars['Float']['output'];
};

/** ChatNotificationSetting */
export type ChatNotificationSetting = {
  __typename?: 'ChatNotificationSetting';
  messageCreateEnabled: Scalars['Boolean']['output'];
};

export type ChatNotificationSettingInput = {
  messageCreateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Chat list subscription event */
export type ChatSubscriptionEvent = {
  __typename?: 'ChatSubscriptionEvent';
  chat?: Maybe<Chat>;
  chatId: Scalars['Float']['output'];
  eventType: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

/** chat users */
export type ChatUser = {
  __typename?: 'ChatUser';
  chatId: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: DisplayUser;
  userId: Scalars['String']['output'];
};

/** CheckList */
export type CheckList = {
  __typename?: 'CheckList';
  createdAt: Scalars['DateTime']['output'];
  createdBy: DisplayUser;
  customFieldAttachments: Array<CheckListCustomFieldAttachment>;
  customFieldDateValues: Array<CheckListCustomFieldDateValue>;
  customFieldDatetimeValues: Array<CheckListCustomFieldDatetimeValue>;
  customFieldFloatValues: Array<CheckListCustomFieldFloatValue>;
  customFieldIntValues: Array<CheckListCustomFieldIntValue>;
  customFieldNotes: Array<CheckListCustomFieldNote>;
  customFieldSelectValues: Array<CheckListCustomFieldSelectValue>;
  customFieldStampValues: Array<CheckListCustomFieldStampValue>;
  customFieldTextValues: Array<CheckListCustomFieldTextValue>;
  id: Scalars['Int']['output'];
  template: CheckListTemplate;
  templateId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: DisplayUser;
  workOrder?: Maybe<WorkOrder>;
  workOrderId?: Maybe<Scalars['Int']['output']>;
};

/** CheckListCustomField */
export type CheckListCustomField = {
  __typename?: 'CheckListCustomField';
  defaults: Array<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionAttachments: Array<DescriptionAttachment>;
  descriptionLexical?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  options: Array<CheckListCustomFieldOption>;
  projectId: Scalars['Float']['output'];
  triggers: Array<CheckListCustomFieldTrigger>;
  type: CheckListCustomFieldType;
};

/** チェックリストのカスタムフィールドごとの添付ファイル */
export type CheckListCustomFieldAttachment = {
  __typename?: 'CheckListCustomFieldAttachment';
  contentType: Scalars['String']['output'];
  customFieldId: Scalars['Int']['output'];
  fileId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  src: Scalars['String']['output'];
};

export type CheckListCustomFieldAttachmentInput = {
  contentType: Scalars['String']['input'];
  customFieldId: Scalars['Int']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** WorkOrderのカスタムフィールド(Date)の値 */
export type CheckListCustomFieldDateValue = {
  __typename?: 'CheckListCustomFieldDateValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['DateTime']['output'];
};

export type CheckListCustomFieldDateValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['DateTime']['input'];
};

/** WorkOrderのカスタムフィールド(Datetime)の値 */
export type CheckListCustomFieldDatetimeValue = {
  __typename?: 'CheckListCustomFieldDatetimeValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['DateTime']['output'];
};

export type CheckListCustomFieldDatetimeValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['DateTime']['input'];
};

/** Work order comment attachment */
export type CheckListCustomFieldDescriptionAttachmentInput = {
  contentType: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** チェックリストのカスタムフィールド(number)の値 */
export type CheckListCustomFieldFloatValue = {
  __typename?: 'CheckListCustomFieldFloatValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type CheckListCustomFieldFloatValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};

export type CheckListCustomFieldInputForNewTemplate = {
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionAttachments?: InputMaybe<
    Array<CheckListCustomFieldDescriptionAttachmentInput>
  >;
  descriptionLexical?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  options?: InputMaybe<Array<NewCheckListCustomFieldOption>>;
  order?: InputMaybe<Scalars['Int']['input']>;
  triggers: Array<CheckListCustomFieldTriggerInput>;
  type: CheckListCustomFieldType;
};

/** チェックリストのカスタムフィールド(number)の値 */
export type CheckListCustomFieldIntValue = {
  __typename?: 'CheckListCustomFieldIntValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type CheckListCustomFieldIntValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['Int']['input'];
};

/** チェックリストのカスタムフィールドごとの備考の値 */
export type CheckListCustomFieldNote = {
  __typename?: 'CheckListCustomFieldNote';
  customFieldId: Scalars['Int']['output'];
  note: Scalars['String']['output'];
};

export type CheckListCustomFieldNoteInput = {
  customFieldId: Scalars['Int']['input'];
  note: Scalars['String']['input'];
};

/** WorkOrderCustomFieldOption */
export type CheckListCustomFieldOption = {
  __typename?: 'CheckListCustomFieldOption';
  id: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

/** チェックリストのカスタムフィールドの値 */
export type CheckListCustomFieldSelectValue = {
  __typename?: 'CheckListCustomFieldSelectValue';
  customFieldId: Scalars['Int']['output'];
  optionId: Scalars['Float']['output'];
  value: Scalars['String']['output'];
};

export type CheckListCustomFieldSelectValueInput = {
  customFieldId: Scalars['Int']['input'];
  optionId: Scalars['Int']['input'];
};

/** WorkOrderのカスタムフィールド(Stamp)の値 */
export type CheckListCustomFieldStampValue = {
  __typename?: 'CheckListCustomFieldStampValue';
  customFieldId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  stampedAt: Scalars['DateTime']['output'];
  stampedBy: DisplayUser;
};

export type CheckListCustomFieldStampValueInput = {
  customFieldId: Scalars['Int']['input'];
  stampedAt: Scalars['DateTime']['input'];
  stampedById: Scalars['String']['input'];
};

/** チェックリストのカスタムフィールド(テキスト)の値 */
export type CheckListCustomFieldTextValue = {
  __typename?: 'CheckListCustomFieldTextValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type CheckListCustomFieldTextValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

/** CheckListCustomFieldTrigger */
export type CheckListCustomFieldTrigger = {
  __typename?: 'CheckListCustomFieldTrigger';
  condition: CheckListCustomFieldTriggerCondition;
};

/** CheckListCustomFieldTriggerCondition */
export type CheckListCustomFieldTriggerCondition = {
  __typename?: 'CheckListCustomFieldTriggerCondition';
  numberBetween?: Maybe<CheckListCustomFieldTriggerConditionBetween>;
  numberGreaterThan?: Maybe<Scalars['Float']['output']>;
  numberGreaterThanOrEqualTo?: Maybe<Scalars['Float']['output']>;
  numberLessThan?: Maybe<Scalars['Float']['output']>;
  numberLessThanOrEqualTo?: Maybe<Scalars['Float']['output']>;
  numberNotBetween?: Maybe<CheckListCustomFieldTriggerConditionBetween>;
  selectInOptionIds?: Maybe<Array<Scalars['Float']['output']>>;
  selectNotInOptionIds?: Maybe<Array<Scalars['Float']['output']>>;
};

/** CheckListCustomFieldTriggerConditionBetween */
export type CheckListCustomFieldTriggerConditionBetween = {
  __typename?: 'CheckListCustomFieldTriggerConditionBetween';
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

/** CheckListCustomFieldTriggerConditionBetweenInput */
export type CheckListCustomFieldTriggerConditionBetweenInput = {
  max: Scalars['Float']['input'];
  min: Scalars['Float']['input'];
};

/** CheckListCustomFieldTriggerConditionInput */
export type CheckListCustomFieldTriggerConditionInput = {
  numberBetween?: InputMaybe<CheckListCustomFieldTriggerConditionBetweenInput>;
  numberGreaterThan?: InputMaybe<Scalars['Float']['input']>;
  numberGreaterThanOrEqualTo?: InputMaybe<Scalars['Float']['input']>;
  numberLessThan?: InputMaybe<Scalars['Float']['input']>;
  numberLessThanOrEqualTo?: InputMaybe<Scalars['Float']['input']>;
  numberNotBetween?: InputMaybe<CheckListCustomFieldTriggerConditionBetweenInput>;
  selectInOptionValues?: InputMaybe<Array<Scalars['String']['input']>>;
  selectNotInOptionValues?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** CheckListCustomFieldTriggerInput */
export type CheckListCustomFieldTriggerInput = {
  condition: CheckListCustomFieldTriggerConditionInput;
};

export const CheckListCustomFieldType = {
  Date: 'date',
  Datetime: 'datetime',
  Float: 'float',
  Int: 'int',
  Select: 'select',
  Stamp: 'stamp',
  Text: 'text',
} as const;

export type CheckListCustomFieldType =
  (typeof CheckListCustomFieldType)[keyof typeof CheckListCustomFieldType];
/** CheckListNotificationSetting */
export type CheckListNotificationSetting = {
  __typename?: 'CheckListNotificationSetting';
  alertTriggeredEnabled: Scalars['Boolean']['output'];
};

export type CheckListNotificationSettingInput = {
  alertTriggeredEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** CheckListTemplate */
export type CheckListTemplate = {
  __typename?: 'CheckListTemplate';
  checkLists: Array<CheckList>;
  description?: Maybe<Scalars['String']['output']>;
  groupIds: Array<Scalars['Int']['output']>;
  groups: Array<Group>;
  id: Scalars['Int']['output'];
  isIndividualExecutionLocked: Scalars['Boolean']['output'];
  items: Array<CheckListTemplateItem>;
  name: Scalars['String']['output'];
  projectId: Scalars['Float']['output'];
  workOrderTemplate: WorkOrderTemplateForCheckListTemplate;
};

export type CheckListTemplateFilterArgs = {
  group?: InputMaybe<CheckListTemplateFilterIdsArgs>;
  searchField?: InputMaybe<Scalars['String']['input']>;
  type: CheckListTemplateFilterType;
};

export type CheckListTemplateFilterIdsArgs = {
  ids: Array<Scalars['Int']['input']>;
};

export const CheckListTemplateFilterType = {
  Group: 'Group',
  SearchField: 'SearchField',
} as const;

export type CheckListTemplateFilterType =
  (typeof CheckListTemplateFilterType)[keyof typeof CheckListTemplateFilterType];
export type CheckListTemplateItem =
  | CheckListCustomField
  | CheckListTemplateSection;

/** CheckListTemplateSection */
export type CheckListTemplateSection = {
  __typename?: 'CheckListTemplateSection';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  items: Array<CheckListCustomField>;
  label: Scalars['String']['output'];
};

export type CommandInput = {
  addCheckListCustomFieldAttachment?: InputMaybe<AddCheckListCustomFieldAttachmentCommandInput>;
  addCheckListCustomFieldStamp?: InputMaybe<AddCheckListCustomFieldStampCommandInput>;
  addWorkOrderCheckList?: InputMaybe<AddWorkOrderCheckListCommandInput>;
  addWorkOrderCustomFieldFiles?: InputMaybe<AddWorkOrderCustomFieldFilesCommandInput>;
  addWorkOrderPart?: InputMaybe<AddWorkOrderPartCommandInput>;
  deleteCheckListCustomFieldAttachment?: InputMaybe<DeleteCheckListCustomFieldAttachmentCommandInput>;
  deleteCheckListCustomFieldStamp?: InputMaybe<DeleteCheckListCustomFieldStampCommandInput>;
  deleteWorkOrderCheckList?: InputMaybe<DeleteWorkOrderCheckListCommandInput>;
  deleteWorkOrderCustomFieldFile?: InputMaybe<DeleteWorkOrderCustomFieldFileCommandInput>;
  deleteWorkOrderPart?: InputMaybe<DeleteWorkOrderPartCommandInput>;
  /** client generated uuid */
  id: Scalars['String']['input'];
  patchCheckListCustomField?: InputMaybe<PatchCheckListCustomFieldCommandInput>;
  patchCheckListCustomFieldNote?: InputMaybe<PatchCheckListCustomFieldNoteCommandInput>;
  patchWorkOrder?: InputMaybe<PatchWorkOrderCommandInput>;
  patchWorkOrderCustomField?: InputMaybe<PatchWorkOrderCustomFieldCommandInput>;
  type: CommandType;
  updateCheckListCustomFieldAttachment?: InputMaybe<UpdateCheckListCustomFieldAttachmentCommandInput>;
  updateWorkOrderCustomFieldFile?: InputMaybe<UpdateWorkOrderCustomFieldFileCommandInput>;
  updateWorkOrderPart?: InputMaybe<UpdateWorkOrderPartCommandInput>;
};

export const CommandType = {
  AddCheckListCustomFieldAttachment: 'AddCheckListCustomFieldAttachment',
  AddCheckListCustomFieldStamp: 'AddCheckListCustomFieldStamp',
  AddWorkOrderCheckList: 'AddWorkOrderCheckList',
  AddWorkOrderCustomFieldFiles: 'AddWorkOrderCustomFieldFiles',
  AddWorkOrderPart: 'AddWorkOrderPart',
  DeleteCheckListCustomFieldAttachment: 'DeleteCheckListCustomFieldAttachment',
  DeleteCheckListCustomFieldStamp: 'DeleteCheckListCustomFieldStamp',
  DeleteWorkOrderCheckList: 'DeleteWorkOrderCheckList',
  DeleteWorkOrderCustomFieldFile: 'DeleteWorkOrderCustomFieldFile',
  DeleteWorkOrderPart: 'DeleteWorkOrderPart',
  PatchCheckListCustomField: 'PatchCheckListCustomField',
  PatchCheckListCustomFieldNote: 'PatchCheckListCustomFieldNote',
  PatchWorkOrder: 'PatchWorkOrder',
  PatchWorkOrderCustomField: 'PatchWorkOrderCustomField',
  UpdateCheckListCustomFieldAttachment: 'UpdateCheckListCustomFieldAttachment',
  UpdateWorkOrderCustomFieldFile: 'UpdateWorkOrderCustomFieldFile',
  UpdateWorkOrderPart: 'UpdateWorkOrderPart',
} as const;

export type CommandType = (typeof CommandType)[keyof typeof CommandType];
export type CommandsResult = {
  __typename?: 'CommandsResult';
  affectedCheckListIds: Array<Scalars['Int']['output']>;
  affectedCheckLists: Array<CheckList>;
  affectedWorkOrderIds: Array<Scalars['Int']['output']>;
  affectedWorkOrders: Array<WorkOrder>;
  success: Scalars['Boolean']['output'];
};

/** Comment */
export type Comment = HasCreatedBy & {
  __typename?: 'Comment';
  bodyLexical: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  createdById: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  parentId: Scalars['Int']['output'];
  parentType: CommentParentType;
  report?: Maybe<Report>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Comment */
export type CommentEvent = HasCreatedBy & {
  __typename?: 'CommentEvent';
  comment?: Maybe<Comment>;
  commentId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  createdById: Scalars['String']['output'];
  eventType: CommentEventType;
  id: Scalars['Int']['output'];
};

export const CommentEventType = {
  Create: 'create',
} as const;

export type CommentEventType =
  (typeof CommentEventType)[keyof typeof CommentEventType];
export const CommentParentType = {
  Report: 'Report',
} as const;

export type CommentParentType =
  (typeof CommentParentType)[keyof typeof CommentParentType];
/** company */
export type Company = {
  __typename?: 'Company';
  id: Scalars['Float']['output'];
  locale?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offices?: Maybe<Array<Office>>;
  setting?: Maybe<CompanySetting>;
  timezone: Scalars['String']['output'];
};

/** CompanySetting */
export type CompanySetting = {
  __typename?: 'CompanySetting';
  accessAiVoice: Scalars['Boolean']['output'];
  accessCheckList: Scalars['Boolean']['output'];
  accessGroup: Scalars['Boolean']['output'];
  accessManual: Scalars['Boolean']['output'];
  accessProduct: Scalars['Boolean']['output'];
  accessReport: Scalars['Boolean']['output'];
  accessRequest: Scalars['Boolean']['output'];
  accessSchedule: Scalars['Boolean']['output'];
  accessSuggest: Scalars['Boolean']['output'];
  assetHierarchies: Array<AssetHierarchy>;
  bottomNavigationItems: Array<CompanySettingBottomNavigationItem>;
  customWorkOrderStatuses: Array<CustomWorkOrderStatus>;
  maxAssetHierarchyLevel: Scalars['Float']['output'];
  reportBaseDateType: ReportBaseDateType;
  suggest: Suggest;
};

/** CompanySettingBottomNavigationItem */
export type CompanySettingBottomNavigationItem = {
  __typename?: 'CompanySettingBottomNavigationItem';
  type: BottomNavigationTypeEnum;
};

/** CountNotDoneWorkOrderByStatus */
export type CountNotDoneWorkOrderByStatus = {
  __typename?: 'CountNotDoneWorkOrderByStatus';
  count: Scalars['Float']['output'];
  status: WorkOrderStatus;
};

/** CountWorkOrderByAsset */
export type CountWorkOrderByAsset = {
  __typename?: 'CountWorkOrderByAsset';
  count: Scalars['Float']['output'];
  countIncludingChild: Scalars['Float']['output'];
  downtime: Scalars['Float']['output'];
  downtimeIncludingChild: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  level: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  parentAsset?: Maybe<Asset>;
};

/** CountWorkOrderByAssignee */
export type CountWorkOrderByAssignee = {
  __typename?: 'CountWorkOrderByAssignee';
  count: Scalars['Float']['output'];
  downtime: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

/** CountWorkOrderByCreateDate */
export type CountWorkOrderByCreateDate = {
  __typename?: 'CountWorkOrderByCreateDate';
  count: Scalars['Float']['output'];
  date: Scalars['String']['output'];
  downtime: Scalars['Float']['output'];
};

/** CountWorkOrderByCustomFieldOption */
export type CountWorkOrderByCustomField = {
  __typename?: 'CountWorkOrderByCustomField';
  count: Scalars['Float']['output'];
  downtime: Scalars['Float']['output'];
  optionId: Scalars['Float']['output'];
  value: Scalars['String']['output'];
};

/** CountWorkOrderByCreateDate */
export type CountWorkOrderByCustomFieldAndCreateDate = {
  __typename?: 'CountWorkOrderByCustomFieldAndCreateDate';
  counts: Array<CountWorkOrderByCreateDate>;
  optionId: Scalars['Int']['output'];
  optionValue: Scalars['String']['output'];
};

/** BaseCountWorkOrderPart */
export type CountWorkOrderPart = {
  __typename?: 'CountWorkOrderPart';
  count: Scalars['Float']['output'];
  currencyCode: CurrencyCodeType;
  distinctCount: Scalars['Float']['output'];
  previous: BaseCountWorkOrderPart;
  totalCost: Scalars['Float']['output'];
  totalQuantity: Scalars['Float']['output'];
};

/** CountWorkOrderPartByPart */
export type CountWorkOrderPartByPart = {
  __typename?: 'CountWorkOrderPartByPart';
  cost: Scalars['Float']['output'];
  count: Scalars['Float']['output'];
  currencyCode: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orderPoint: Scalars['Float']['output'];
  stock: Scalars['Float']['output'];
  totalCost: Scalars['Float']['output'];
  unit?: Maybe<Scalars['String']['output']>;
};

export type CreateStoppageReasonInput = {
  name: Scalars['String']['input'];
};

export const CurrencyCodeEnum = {
  Jpy: 'JPY',
  Thb: 'THB',
  Usd: 'USD',
  Vnd: 'VND',
} as const;

export type CurrencyCodeEnum =
  (typeof CurrencyCodeEnum)[keyof typeof CurrencyCodeEnum];
export const CurrencyCodeType = {
  Jpy: 'JPY',
  Thb: 'THB',
  Usd: 'USD',
  Vnd: 'VND',
} as const;

export type CurrencyCodeType =
  (typeof CurrencyCodeType)[keyof typeof CurrencyCodeType];
/** CustomWorkOrderStatus */
export type CustomWorkOrderStatus = {
  __typename?: 'CustomWorkOrderStatus';
  label: Scalars['String']['output'];
  status: WorkOrderStatus;
};

export const DayOfWeek = {
  Friday: 'Friday',
  Monday: 'Monday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  Thursday: 'Thursday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
} as const;

export type DayOfWeek = (typeof DayOfWeek)[keyof typeof DayOfWeek];
export type DeleteAssetFileInput = {
  assetId: Scalars['Float']['input'];
  fileId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
};

export type DeleteCheckListCustomFieldAttachmentCommandInput = {
  attachmentId: Scalars['Int']['input'];
  checkListId: Scalars['Int']['input'];
  customFieldId: Scalars['Int']['input'];
};

export type DeleteCheckListCustomFieldStampCommandInput = {
  checkListId: Scalars['Int']['input'];
  customFieldId: Scalars['Int']['input'];
};

export type DeletePartDocumentInput = {
  fileId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  partId: Scalars['Float']['input'];
};

export type DeletePartFileInput = {
  fileId: Scalars['String']['input'];
  partId: Scalars['Float']['input'];
};

/** DeleteReportTemplateResult */
export type DeleteReportTemplateResult = {
  __typename?: 'DeleteReportTemplateResult';
  id: Scalars['Int']['output'];
};

export type DeleteRequestCustomFieldFileInput = {
  customFieldId: Scalars['Int']['input'];
  fileId: Scalars['String']['input'];
  requestId: Scalars['Int']['input'];
};

export type DeleteWorkOrderCheckListCommandInput = {
  checkListId: Scalars['Int']['input'];
  workOrderId: Scalars['Int']['input'];
};

export type DeleteWorkOrderCustomFieldFileCommandInput = {
  customFieldId: Scalars['Int']['input'];
  valueId: Scalars['Int']['input'];
  workOrderId: Scalars['Int']['input'];
};

export type DeleteWorkOrderCustomFieldFileInput = {
  customFieldId: Scalars['Int']['input'];
  fileId: Scalars['String']['input'];
  workOrderId: Scalars['Int']['input'];
};

export type DeleteWorkOrderPartCommandInput = {
  partId: Scalars['Int']['input'];
  workOrderId: Scalars['Int']['input'];
};

/** CheckList Custom Field Description Attachment */
export type DescriptionAttachment = {
  __typename?: 'DescriptionAttachment';
  contentType: Scalars['String']['output'];
  customFieldId: Scalars['Int']['output'];
  fileId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  src: Scalars['String']['output'];
};

/** DisplayPart  */
export type DisplayPart = {
  __typename?: 'DisplayPart';
  area?: Maybe<Scalars['String']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  manufacturer?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  officeId: Scalars['Float']['output'];
  orderPoint?: Maybe<Scalars['Float']['output']>;
  qrBarCode?: Maybe<Scalars['String']['output']>;
  stock: Scalars['Float']['output'];
  unit?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** DisplayRequest */
export type DisplayRequest = {
  __typename?: 'DisplayRequest';
  asset?: Maybe<Asset>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: DisplayUser;
  id: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Display User */
export type DisplayUser = {
  __typename?: 'DisplayUser';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

/** Display WorkOrder */
export type DisplayWorkOrder = {
  __typename?: 'DisplayWorkOrder';
  asset?: Maybe<Asset>;
  createdAt: Scalars['DateTime']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  priority: WorkOrderPriority;
  status: WorkOrderStatus;
  stoppage?: Maybe<WorkOrderStoppage>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export const DocumentTypeEnum = {
  Document: 'document',
  Link: 'link',
} as const;

export type DocumentTypeEnum =
  (typeof DocumentTypeEnum)[keyof typeof DocumentTypeEnum];
/** ExportResult */
export type ExportResult = {
  __typename?: 'ExportResult';
  csv: Scalars['String']['output'];
};

/** FileUploadUrl */
export type FileUploadUrl = {
  __typename?: 'FileUploadUrl';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type Group = {
  __typename?: 'Group';
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  officeId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: User;
  updatedById: Scalars['String']['output'];
  users: Array<User>;
};

export type HasComments = {
  comments: Array<Comment>;
  id: Scalars['Int']['output'];
};

export type HasCreatedBy = {
  createdBy: User;
  createdById: Scalars['String']['output'];
};

export type HasNullableWorkOrderCustomField = {
  customField?: Maybe<WorkOrderCustomField>;
  customFieldId?: Maybe<Scalars['Int']['output']>;
};

/** 階層化された設備 */
export type HierarchicalAsset = {
  __typename?: 'HierarchicalAsset';
  childAssets?: Maybe<Array<HierarchicalAsset>>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type HierarchicalAssetInput = {
  childAssets?: InputMaybe<Array<HierarchicalAssetInput>>;
  name: Scalars['String']['input'];
};

/** 階層化された製品 */
export type HierarchicalProduct = {
  __typename?: 'HierarchicalProduct';
  childProducts?: Maybe<Array<HierarchicalProduct>>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type HierarchicalProductInput = {
  childProducts?: InputMaybe<Array<HierarchicalProductInput>>;
  name: Scalars['String']['input'];
};

/** ImportPayloadInput */
export type ImportPayloadInput = {
  fileId: Scalars['String']['input'];
  type: ImportType;
};

/** ImportResult */
export type ImportResult = {
  __typename?: 'ImportResult';
  errors: Array<ImportValidationItem>;
  success: Scalars['Boolean']['output'];
};

export const ImportType = {
  Asset: 'asset',
  Part: 'part',
  Product: 'product',
} as const;

export type ImportType = (typeof ImportType)[keyof typeof ImportType];
/** ImportValidationItem */
export type ImportValidationItem = {
  __typename?: 'ImportValidationItem';
  column: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  row: Scalars['Int']['output'];
};

/** ImportValidationResult */
export type ImportValidationResult = {
  __typename?: 'ImportValidationResult';
  errors: Array<ImportValidationItem>;
  success: Scalars['Boolean']['output'];
  warnings: Array<ImportValidationItem>;
};

export type InboundLinkInput = {
  inboundId: Scalars['Int']['input'];
};

/** LatestUpdatedDates */
export type LatestUpdatedDates = {
  __typename?: 'LatestUpdatedDates';
  assetsDate: Scalars['DateTime']['output'];
};

/** LoginMethod */
export type LoginMethod = {
  __typename?: 'LoginMethod';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  type: LoginMethodType;
};

/** LoginMethodForEmail */
export type LoginMethodForEmail = {
  __typename?: 'LoginMethodForEmail';
  firebaseProviderId?: Maybe<Scalars['String']['output']>;
  type: LoginMethodType;
};

export const LoginMethodType = {
  Saml: 'Saml',
} as const;

export type LoginMethodType =
  (typeof LoginMethodType)[keyof typeof LoginMethodType];
export type Mutation = {
  __typename?: 'Mutation';
  addAsset: Asset;
  addAssetDocument: Asset;
  addChatMessage: ChatMessage;
  addCheckList: Scalars['Int']['output'];
  addCheckListTemplateV2: CheckListTemplate;
  addGroup: Group;
  addHierarchicalAssets: Scalars['Boolean']['output'];
  addHierarchicalProducts: Scalars['Boolean']['output'];
  addOfficeUser: User;
  addPart: Part;
  addPartAssetDependency: PartAssetDependency;
  addPartDocument: Part;
  addPartFile: Part;
  addProduct: Product;
  addRequest: Request;
  addRequestComment: RequestComment;
  /** @deprecated Use addRequestCustomFieldFileValues instead */
  addRequestCustomFieldFileValue: Request;
  addRequestCustomFieldFileValues: Request;
  addStoppageReason: StoppageReason;
  addUserClientNotificationToken: Scalars['Boolean']['output'];
  addWorkOrder: WorkOrder;
  addWorkOrderComment: WorkOrderComment;
  addWorkOrderCustomField: WorkOrderCustomField;
  /** @deprecated Use addWorkOrderCustomFieldFileValues instead */
  addWorkOrderCustomFieldFileValue: WorkOrder;
  addWorkOrderCustomFieldFileValues: WorkOrder;
  addWorkOrderTemplate: WorkOrderTemplate;
  approveWorkflowExecution: WorkflowExecution;
  archiveUser: User;
  archiveWorkOrderTemplate: Scalars['Boolean']['output'];
  cancelWorkflowExecution: WorkflowExecution;
  createChat: Chat;
  createReport: Report;
  createReportComment: Comment;
  createReportTemplate: ReportTemplate;
  deleteReport: Scalars['Boolean']['output'];
  deleteReportTemplate: DeleteReportTemplateResult;
  generateAssetQRCode: Scalars['String']['output'];
  generateAssetsQRCode: Scalars['Float']['output'];
  generateCopyingWorkOrderInputReference: WorkOrderInputReferenceData;
  /** @deprecated Use generateFileUploadUrls instead */
  generateFileUploadUrl: FileUploadUrl;
  generateFileUploadUrls: Array<FileUploadUrl>;
  generateFileUrl: Scalars['String']['output'];
  generateQRcodePDF: Scalars['String']['output'];
  import: ImportResult;
  processCommands: CommandsResult;
  pushWorkOrderNotification: Scalars['Boolean']['output'];
  rejectWorkflowExecution: WorkflowExecution;
  removeAsset: Scalars['Boolean']['output'];
  removeAssetDocument: Asset;
  removeChat: Scalars['Boolean']['output'];
  removeChatMessage: Scalars['Boolean']['output'];
  removeCheckList: Scalars['Boolean']['output'];
  removeCheckListTemplate: Scalars['Boolean']['output'];
  removeGroup: Scalars['Boolean']['output'];
  removePart: Scalars['Boolean']['output'];
  removePartAssetDependency: Asset;
  removePartDocument: Part;
  removePartFileData: Part;
  removeProduct: Scalars['Boolean']['output'];
  removeRequest: Scalars['Boolean']['output'];
  removeRequestComment: Scalars['Boolean']['output'];
  removeRequestCustomFieldFileValue: Request;
  removeWorkOrder: Scalars['Boolean']['output'];
  removeWorkOrderComment: Scalars['Boolean']['output'];
  removeWorkOrderCustomField: Scalars['Boolean']['output'];
  removeWorkOrderCustomFieldFileValue: WorkOrder;
  removeWorkOrderLink: Scalars['Boolean']['output'];
  resubmitReportToWorkflow: Report;
  submitReportToWorkflow: Report;
  suggestKnowledgeBase: Scalars['String']['output'];
  suggestWorkOrderText: Scalars['String']['output'];
  updateAsset: Asset;
  updateChat: Chat;
  updateChatMessage: ChatMessage;
  updateCheckList: Scalars['Boolean']['output'];
  updateCheckListTemplateLock: CheckListTemplate;
  updateCheckListTemplateV2: CheckListTemplate;
  updateGroup: Group;
  updateMe: User;
  updateOffice: Office;
  updatePart: Part;
  updatePartAssetDependency: PartAssetDependency;
  updatePartStock: Part;
  updateProduct: Product;
  updateReport: Report;
  updateReportTemplate: ReportTemplate;
  updateRequest: Request;
  updateRequestComment: RequestComment;
  updateRequestCustomFieldFileValue: Request;
  updateRequestTemplate: RequestTemplate;
  updateUser: User;
  updateWorkOrder: WorkOrder;
  updateWorkOrderComment: WorkOrderComment;
  updateWorkOrderCustomField: WorkOrderCustomField;
  updateWorkOrderCustomFieldFileValue: WorkOrder;
  updateWorkOrderPart: WorkOrder;
  updateWorkOrderTemplate: WorkOrderTemplate;
};

export type MutationAddAssetArgs = {
  newAssetInput: NewAssetInput;
};

export type MutationAddAssetDocumentArgs = {
  newAssetDocumentInput: NewAssetFileInput;
};

export type MutationAddChatMessageArgs = {
  newChatMessageInput: NewChatMessageInput;
};

export type MutationAddCheckListArgs = {
  newCheckListInput: NewCheckListInput;
};

export type MutationAddCheckListTemplateV2Args = {
  newCheckListTemplateInput: NewCheckListTemplateInput;
};

export type MutationAddGroupArgs = {
  newGroupInput: NewGroupInput;
};

export type MutationAddHierarchicalAssetsArgs = {
  newHierarchicalAssetsInput: NewHierarchicalAssetsInput;
};

export type MutationAddHierarchicalProductsArgs = {
  newHierarchicalProductsInput: NewHierarchicalProductsInput;
};

export type MutationAddOfficeUserArgs = {
  newUserInput: NewUserInput;
};

export type MutationAddPartArgs = {
  newPartInput: NewPartInput;
};

export type MutationAddPartAssetDependencyArgs = {
  input: AddPartAssetDependencyInput;
};

export type MutationAddPartDocumentArgs = {
  newPartDocumentInput: NewPartDocumentInput;
};

export type MutationAddPartFileArgs = {
  newPartFileInput: NewPartFileInput;
};

export type MutationAddProductArgs = {
  newProductInput: NewProductInput;
};

export type MutationAddRequestArgs = {
  newRequestInput: NewRequestInput;
};

export type MutationAddRequestCommentArgs = {
  newRequestCommentInput: NewRequestCommentInput;
};

export type MutationAddRequestCustomFieldFileValueArgs = {
  newRequestCustomFieldFileInput: NewRequestCustomFieldFileInput;
};

export type MutationAddRequestCustomFieldFileValuesArgs = {
  newRequestCustomFieldFilesInput: NewRequestCustomFieldFilesInput;
};

export type MutationAddStoppageReasonArgs = {
  input: CreateStoppageReasonInput;
};

export type MutationAddUserClientNotificationTokenArgs = {
  token: Scalars['String']['input'];
};

export type MutationAddWorkOrderArgs = {
  newWorkOrderInput: NewWorkOrderInput;
};

export type MutationAddWorkOrderCommentArgs = {
  newWorkOrderCommentInput: NewWorkOrderCommentInput;
};

export type MutationAddWorkOrderCustomFieldArgs = {
  newWorkOrderCustomFieldInput: NewWorkOrderCustomFieldInput;
};

export type MutationAddWorkOrderCustomFieldFileValueArgs = {
  newWorkOrderCustomFieldFileInput: NewWorkOrderCustomFieldFileInput;
};

export type MutationAddWorkOrderCustomFieldFileValuesArgs = {
  newWorkOrderCustomFieldFilesInput: NewWorkOrderCustomFieldFilesInput;
};

export type MutationAddWorkOrderTemplateArgs = {
  newWorkOrderTemplateInput: NewWorkOrderTemplateInput;
};

export type MutationApproveWorkflowExecutionArgs = {
  executionId: Scalars['Int']['input'];
};

export type MutationArchiveUserArgs = {
  id: Scalars['String']['input'];
};

export type MutationArchiveWorkOrderTemplateArgs = {
  id: Scalars['Float']['input'];
};

export type MutationCancelWorkflowExecutionArgs = {
  executionId: Scalars['Int']['input'];
};

export type MutationCreateChatArgs = {
  newChatInput: NewChatInput;
};

export type MutationCreateReportArgs = {
  input: NewReportInput;
};

export type MutationCreateReportCommentArgs = {
  input: NewCommentInput;
  reportId: Scalars['Int']['input'];
};

export type MutationCreateReportTemplateArgs = {
  input: NewReportTemplateInput;
};

export type MutationDeleteReportArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteReportTemplateArgs = {
  id: Scalars['Int']['input'];
};

export type MutationGenerateAssetQrCodeArgs = {
  assetId?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationGenerateCopyingWorkOrderInputReferenceArgs = {
  workOrderId: Scalars['Float']['input'];
};

export type MutationGenerateFileUploadUrlArgs = {
  newFileUploadUrlInput: NewFileUploadUrlInput;
};

export type MutationGenerateFileUploadUrlsArgs = {
  newFilesUploadUrlsInput: Array<NewFileUploadUrlInput>;
};

export type MutationGenerateFileUrlArgs = {
  newFileUrlInput: NewFileUrlInput;
};

export type MutationImportArgs = {
  input: ImportPayloadInput;
};

export type MutationProcessCommandsArgs = {
  commands: Array<CommandInput>;
};

export type MutationPushWorkOrderNotificationArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRejectWorkflowExecutionArgs = {
  executionId: Scalars['Int']['input'];
};

export type MutationRemoveAssetArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveAssetDocumentArgs = {
  deleteAssetDocument: DeleteAssetFileInput;
};

export type MutationRemoveChatArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveChatMessageArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveCheckListArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveCheckListTemplateArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveGroupArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemovePartArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemovePartAssetDependencyArgs = {
  input: RemovePartAssetDependencyInput;
};

export type MutationRemovePartDocumentArgs = {
  deletePartDocument: DeletePartDocumentInput;
};

export type MutationRemovePartFileDataArgs = {
  deletePartFileInput: DeletePartFileInput;
};

export type MutationRemoveProductArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveRequestArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveRequestCommentArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveRequestCustomFieldFileValueArgs = {
  deleteRequestCustomFieldFileInput: DeleteRequestCustomFieldFileInput;
};

export type MutationRemoveWorkOrderArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveWorkOrderCommentArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveWorkOrderCustomFieldArgs = {
  id: Scalars['Float']['input'];
};

export type MutationRemoveWorkOrderCustomFieldFileValueArgs = {
  deleteWorkOrderCustomFieldFileInput: DeleteWorkOrderCustomFieldFileInput;
};

export type MutationRemoveWorkOrderLinkArgs = {
  id: Scalars['Int']['input'];
};

export type MutationResubmitReportToWorkflowArgs = {
  id: Scalars['Int']['input'];
};

export type MutationSubmitReportToWorkflowArgs = {
  id: Scalars['Int']['input'];
};

export type MutationSuggestKnowledgeBaseArgs = {
  question: Scalars['String']['input'];
};

export type MutationSuggestWorkOrderTextArgs = {
  suggestWorkOrderTextInput: SuggestWorkOrderTextInput;
};

export type MutationUpdateAssetArgs = {
  updateAssetInput: UpdateAssetInput;
};

export type MutationUpdateChatArgs = {
  updateChatInput: UpdateChatInput;
};

export type MutationUpdateChatMessageArgs = {
  updateChatMessageInput: UpdateChatMessageInput;
};

export type MutationUpdateCheckListArgs = {
  updateCheckListInput: UpdateCheckListInput;
};

export type MutationUpdateCheckListTemplateLockArgs = {
  updateCheckListTemplateLockInput: UpdateCheckListTemplateLockInput;
};

export type MutationUpdateCheckListTemplateV2Args = {
  updateCheckListTemplateInput: UpdateCheckListTemplateInput;
};

export type MutationUpdateGroupArgs = {
  updateGroupInput: UpdateGroupInput;
};

export type MutationUpdateMeArgs = {
  updateMeInput: UpdateMeInput;
};

export type MutationUpdateOfficeArgs = {
  input: UpdateOfficeInput;
};

export type MutationUpdatePartArgs = {
  updatePartInput: UpdatePartInput;
};

export type MutationUpdatePartAssetDependencyArgs = {
  input: UpdatePartAssetDependencyInput;
};

export type MutationUpdatePartStockArgs = {
  updatePartStockInput: UpdatePartStockInput;
};

export type MutationUpdateProductArgs = {
  updateProductInput: UpdateProductInput;
};

export type MutationUpdateReportArgs = {
  input: UpdateReportInput;
};

export type MutationUpdateReportTemplateArgs = {
  input: UpdateReportTemplateInput;
};

export type MutationUpdateRequestArgs = {
  updateRequestInput: UpdateRequestInput;
};

export type MutationUpdateRequestCommentArgs = {
  updateRequestCommentInput: UpdateRequestCommentInput;
};

export type MutationUpdateRequestCustomFieldFileValueArgs = {
  updateRequestCustomFieldFileInput: UpdateRequestCustomFieldFileInput;
};

export type MutationUpdateRequestTemplateArgs = {
  updateRequestTemplateInput: UpdateRequestTemplateInput;
};

export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};

export type MutationUpdateWorkOrderArgs = {
  updateWorkOrderInput: UpdateWorkOrderInput;
};

export type MutationUpdateWorkOrderCommentArgs = {
  updateWorkOrderCommentInput: UpdateWorkOrderCommentInput;
};

export type MutationUpdateWorkOrderCustomFieldArgs = {
  updateWorkOrderCustomFieldInput: UpdateWorkOrderCustomFieldInput;
};

export type MutationUpdateWorkOrderCustomFieldFileValueArgs = {
  updateWorkOrderCustomFieldFileInput: UpdateWorkOrderCustomFieldFileInput;
};

export type MutationUpdateWorkOrderPartArgs = {
  updateWorkOrderPartInput: UpdateWorkOrderPartInput;
};

export type MutationUpdateWorkOrderTemplateArgs = {
  updateWorkOrderTemplateInput: UpdateWorkOrderTemplateInput;
};

export type NewAssetFileInput = {
  assetId?: InputMaybe<Scalars['Float']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
  type: DocumentTypeEnum;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type NewAssetInput = {
  assetDocuments?: InputMaybe<Array<NewAssetFileInput>>;
  controlNumber?: InputMaybe<Scalars['String']['input']>;
  customFieldDateValues?: InputMaybe<Array<AssetCustomFieldDateValueInput>>;
  customFieldDatetimeValues?: InputMaybe<
    Array<AssetCustomFieldDatetimeValueInput>
  >;
  customFieldFloatValues?: InputMaybe<Array<AssetCustomFieldFloatValueInput>>;
  customFieldIntValues?: InputMaybe<Array<AssetCustomFieldIntValueInput>>;
  customFieldSelectValues?: InputMaybe<Array<AssetCustomFieldSelectValueInput>>;
  customFieldTextValues?: InputMaybe<Array<AssetCustomFieldTextValueInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  manufactureDate?: InputMaybe<Scalars['DateTime']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  officeId?: InputMaybe<Scalars['Float']['input']>;
  parentAssetId?: InputMaybe<Scalars['Float']['input']>;
  qrBarCode?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  stoppageReasonIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NewChatInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NewChatMessageInput = {
  attachments?: InputMaybe<Array<ChatMessageAttachmentInput>>;
  chatId: Scalars['Float']['input'];
  mentionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  message: Scalars['String']['input'];
  /** LexicalでフォーマットされたJsonのメッセージ */
  messageLexical: Scalars['String']['input'];
  /** Pain Textのメッセージ */
  messagePlainText: Scalars['String']['input'];
  replyMessageId?: InputMaybe<Scalars['Float']['input']>;
};

export type NewCheckListCustomFieldOption = {
  value: Scalars['String']['input'];
};

/** WorkOrderに紐づいてるチェックリスト */
export type NewCheckListInput = {
  customFieldAttachments?: InputMaybe<
    Array<CheckListCustomFieldAttachmentInput>
  >;
  customFieldDateValues?: InputMaybe<Array<CheckListCustomFieldDateValueInput>>;
  customFieldDatetimeValues?: InputMaybe<
    Array<CheckListCustomFieldDatetimeValueInput>
  >;
  customFieldFloatValues?: InputMaybe<
    Array<CheckListCustomFieldFloatValueInput>
  >;
  customFieldIntValues?: InputMaybe<Array<CheckListCustomFieldIntValueInput>>;
  customFieldNotes?: InputMaybe<Array<CheckListCustomFieldNoteInput>>;
  customFieldSelectValues?: InputMaybe<
    Array<CheckListCustomFieldSelectValueInput>
  >;
  customFieldStampValues?: InputMaybe<
    Array<CheckListCustomFieldStampValueInput>
  >;
  customFieldTextValues?: InputMaybe<Array<CheckListCustomFieldTextValueInput>>;
  newCustomFields?: InputMaybe<Array<CheckListCustomFieldInputForNewTemplate>>;
  newSections?: InputMaybe<Array<NewCheckListTemplateSectionInput>>;
  templateId: Scalars['Int']['input'];
  workOrderId?: InputMaybe<Scalars['Int']['input']>;
};

export type NewCheckListTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  newCustomFields: Array<CheckListCustomFieldInputForNewTemplate>;
  newSections: Array<NewCheckListTemplateSectionInput>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  workOrderTemplate: WorkOrderTemplateForCheckListInput;
};

export type NewCheckListTemplateSectionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  items: Array<CheckListCustomFieldInputForNewTemplate>;
  label: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type NewCommentInput = {
  bodyLexical: Scalars['String']['input'];
};

export type NewFileUploadUrlInput = {
  contentType: Scalars['String']['input'];
};

export type NewFileUrlInput = {
  path: Scalars['String']['input'];
};

export type NewGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  officeId?: InputMaybe<Scalars['Float']['input']>;
  userIds: Array<Scalars['String']['input']>;
};

export type NewHierarchicalAssetsInput = {
  assets: Array<HierarchicalAssetInput>;
  officeId?: InputMaybe<Scalars['Int']['input']>;
};

export type NewHierarchicalProductsInput = {
  officeId?: InputMaybe<Scalars['Int']['input']>;
  products: Array<HierarchicalProductInput>;
};

export type NewPartDocumentInput = {
  contentType?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  partId?: InputMaybe<Scalars['Float']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  type: DocumentTypeEnum;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type NewPartFileInput = {
  contentType: Scalars['String']['input'];
  fileId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  partId?: InputMaybe<Scalars['Float']['input']>;
};

export type NewPartInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  assetIds: Array<Scalars['Int']['input']>;
  cost?: InputMaybe<Scalars['Float']['input']>;
  currencyCode?: InputMaybe<CurrencyCodeEnum>;
  customFieldDateValues?: InputMaybe<Array<PartCustomFieldDateValueInput>>;
  customFieldDatetimeValues?: InputMaybe<
    Array<PartCustomFieldDatetimeValueInput>
  >;
  customFieldFloatValues?: InputMaybe<Array<PartCustomFieldFloatValueInput>>;
  customFieldIntValues?: InputMaybe<Array<PartCustomFieldIntValueInput>>;
  customFieldSelectValues?: InputMaybe<Array<PartCustomFieldSelectValueInput>>;
  customFieldTextValues?: InputMaybe<Array<PartCustomFieldTextValueInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  officeId?: InputMaybe<Scalars['Int']['input']>;
  orderPoint?: InputMaybe<Scalars['Float']['input']>;
  partDocuments?: InputMaybe<Array<NewPartDocumentInput>>;
  partFiles?: InputMaybe<Array<NewPartFileInput>>;
  qrBarCode?: InputMaybe<Scalars['String']['input']>;
  stock: Scalars['Float']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type NewProductInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  officeId?: InputMaybe<Scalars['Int']['input']>;
  parentProductId?: InputMaybe<Scalars['Int']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type NewReportInput = {
  blockValues: Array<ReportVersionBlockValueInput>;
  reportTemplateVersionId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type NewReportTemplateInput = {
  layout: ReportLayoutInput;
  name: Scalars['String']['input'];
  workflow: WorkflowDefinitionInput;
};

export type NewRequestCommentInput = {
  attachments?: InputMaybe<Array<RequestCommentAttachmentInput>>;
  comment: Scalars['String']['input'];
  commentLexical?: InputMaybe<Scalars['String']['input']>;
  mentionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  replyCommentId?: InputMaybe<Scalars['Float']['input']>;
  requestId: Scalars['Float']['input'];
};

export type NewRequestCustomFieldFileInput = {
  contentType: Scalars['String']['input'];
  customFieldId: Scalars['Float']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  requestId: Scalars['Float']['input'];
};

export type NewRequestCustomFieldFilesInput = {
  fileValues: Array<RequestCustomFieldFileValueInput>;
  requestId: Scalars['Float']['input'];
};

export type NewRequestInput = {
  assetId?: InputMaybe<Scalars['Float']['input']>;
  assignees?: InputMaybe<Array<RequestAssigneeInput>>;
  customFieldDateValues?: InputMaybe<Array<RequestCustomFieldDateValueInput>>;
  customFieldDatetimeValues?: InputMaybe<
    Array<RequestCustomFieldDatetimeValueInput>
  >;
  customFieldFileValues?: InputMaybe<Array<RequestCustomFieldFileValueInput>>;
  customFieldFloatValues?: InputMaybe<Array<RequestCustomFieldFloatValueInput>>;
  customFieldIntValues?: InputMaybe<Array<RequestCustomFieldIntValueInput>>;
  customFieldSelectValues?: InputMaybe<
    Array<RequestCustomFieldSelectValueInput>
  >;
  customFieldTextValues?: InputMaybe<Array<RequestCustomFieldTextValueInput>>;
  customFieldUserValues?: InputMaybe<Array<RequestCustomFieldUserValueInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  priority?: InputMaybe<RequestPriority>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  stoppage?: InputMaybe<RequestStoppageInput>;
  stoppageReasonId?: InputMaybe<Scalars['Int']['input']>;
  templateId: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type NewUserInput = {
  accessPolicyId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  loginMethodId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['String']['input'];
};

/** WorkOrderに紐づいてるコメント */
export type NewWorkOrderCommentInput = {
  attachments?: InputMaybe<Array<WorkOrderCommentAttachmentInput>>;
  comment: Scalars['String']['input'];
  /** LexicalでフォーマットされたJsonをStringで返す */
  commentLexical?: InputMaybe<Scalars['String']['input']>;
  mentionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  replyCommentId?: InputMaybe<Scalars['Float']['input']>;
  workOrderId: Scalars['Float']['input'];
};

export type NewWorkOrderCustomFieldFileInput = {
  contentType: Scalars['String']['input'];
  customFieldId: Scalars['Float']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  workOrderId: Scalars['Float']['input'];
};

export type NewWorkOrderCustomFieldFilesInput = {
  fileValues: Array<WorkOrderCustomFieldFileValueInput>;
  workOrderId: Scalars['Float']['input'];
};

export type NewWorkOrderCustomFieldInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionLexical?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  multiple?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<Array<NewWorkOrderCustomFieldOption>>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  rows?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
};

export type NewWorkOrderCustomFieldOption = {
  value: Scalars['String']['input'];
};

export type NewWorkOrderInput = {
  assetId?: InputMaybe<Scalars['Float']['input']>;
  assignees?: InputMaybe<Array<WorkOrderAssigneeInput>>;
  checkLists?: InputMaybe<Array<NewCheckListInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['String']['input']>;
  customFieldDateValues?: InputMaybe<Array<WorkOrderCustomFieldDateValueInput>>;
  customFieldDatetimeValues?: InputMaybe<
    Array<WorkOrderCustomFieldDatetimeValueInput>
  >;
  customFieldFileValues?: InputMaybe<Array<WorkOrderCustomFieldFileValueInput>>;
  customFieldFloatValues?: InputMaybe<
    Array<WorkOrderCustomFieldFloatValueInput>
  >;
  customFieldIntValues?: InputMaybe<Array<WorkOrderCustomFieldIntValueInput>>;
  customFieldSelectValues?: InputMaybe<
    Array<WorkOrderCustomFieldSelectValueInput>
  >;
  customFieldTextValues?: InputMaybe<Array<WorkOrderCustomFieldTextValueInput>>;
  customFieldUserValues?: InputMaybe<Array<WorkOrderCustomFieldUserValueInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  inboundLink?: InputMaybe<InboundLinkInput>;
  parts?: InputMaybe<Array<WorkOrderPartInput>>;
  priority?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  scheduleDueDate?: InputMaybe<ScheduleDueDateInput>;
  scheduleMonthlyDate?: InputMaybe<ScheduleMonthlyDateInput>;
  scheduleMonthlyDayOfWeekDate?: InputMaybe<ScheduleMonthlyDayOfWeekDateInput>;
  scheduleWeeklyDate?: InputMaybe<ScheduleWeeklyDateInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  stoppage?: InputMaybe<WorkOrderStoppageInput>;
  stoppageReasonId?: InputMaybe<Scalars['Int']['input']>;
  templateId: Scalars['Float']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type NewWorkOrderTemplateInput = {
  assetEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  assetId?: InputMaybe<Scalars['Float']['input']>;
  assigneeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  checkListEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  defaultAssignees?: InputMaybe<Array<WorkOrderTemplateDefaultAssigneeInput>>;
  defaultCheckListTemplates?: InputMaybe<
    Array<WorkOrderTemplateDefaultCheckListTemplate>
  >;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  dueEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  groupEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  partEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  priorityEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  productEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['Int']['input']>;
  scheduleDueDates?: InputMaybe<Array<WorkOrderTemplateScheduleDueDateInput>>;
  scheduleMonthlies?: InputMaybe<Array<WorkOrderTemplateScheduleMonthlyInput>>;
  scheduleMonthlyDayOfWeeks?: InputMaybe<
    Array<WorkOrderTemplateScheduleMonthlyDayOfWeekInput>
  >;
  scheduleWeeklies?: InputMaybe<Array<WorkOrderTemplateScheduleWeeklyInput>>;
  stoppageEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  titleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  useSchedule?: InputMaybe<Scalars['Boolean']['input']>;
};

/** NotificationEvent */
export type NotificationEvent = {
  __typename?: 'NotificationEvent';
  chat?: Maybe<Chat>;
  chatMessage?: Maybe<BaseChatMessage>;
  checkList?: Maybe<CheckList>;
  checkListId?: Maybe<Scalars['Float']['output']>;
  commentEvent?: Maybe<CommentEvent>;
  commentEventId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<DisplayUser>;
  description: Scalars['String']['output'];
  eventType: NotificationEventType;
  id: Scalars['Float']['output'];
  part?: Maybe<DisplayPart>;
  request?: Maybe<DisplayRequest>;
  requestComment?: Maybe<BaseRequestComment>;
  workOrder?: Maybe<DisplayWorkOrder>;
  workOrderComment?: Maybe<WorkOrderBaseComment>;
  workOrders?: Maybe<Array<DisplayWorkOrder>>;
  workflowExecutionEvent?: Maybe<WorkflowExecutionEvent>;
  workflowExecutionEventId?: Maybe<Scalars['Int']['output']>;
};

export const NotificationEventType = {
  ChatMessage: 'chatMessage',
  CheckListAlertTriggered: 'checkListAlertTriggered',
  CommentCreated: 'commentCreated',
  Part: 'part',
  Request: 'request',
  RequestApproved: 'requestApproved',
  RequestAssigned: 'requestAssigned',
  RequestAssignedToGroup: 'requestAssignedToGroup',
  RequestAssignedToGroupMember: 'requestAssignedToGroupMember',
  RequestCancelled: 'requestCancelled',
  RequestComment: 'requestComment',
  RequestRejected: 'requestRejected',
  WorkOrder: 'workOrder',
  WorkOrderComment: 'workOrderComment',
  WorkOrderDueDate: 'workOrderDueDate',
  WorkflowApprovalRequested: 'workflowApprovalRequested',
  WorkflowApproved: 'workflowApproved',
  WorkflowRejected: 'workflowRejected',
} as const;

export type NotificationEventType =
  (typeof NotificationEventType)[keyof typeof NotificationEventType];
/** NotificationEvent */
export type NotificationEvents = {
  __typename?: 'NotificationEvents';
  notificationEvents: Array<NotificationEvent>;
  totalCount: Scalars['Float']['output'];
};

/** Notifications */
export type Notifications = {
  __typename?: 'Notifications';
  newChatMessageCount: Scalars['Float']['output'];
  newRequestCount: Scalars['Float']['output'];
};

/** office  */
export type Office = {
  __typename?: 'Office';
  companyId: Scalars['Float']['output'];
  currencySettings: Array<OfficeCurrencySetting>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  projects?: Maybe<Array<Project>>;
};

/** オフィスごとの通貨設定 */
export type OfficeCurrencySetting = {
  __typename?: 'OfficeCurrencySetting';
  currencyCode: CurrencyCodeType;
  isDefault: Scalars['Boolean']['output'];
};

export const Order = {
  Asc: 'Asc',
  Desc: 'Desc',
} as const;

export type Order = (typeof Order)[keyof typeof Order];
export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaginatedChatMessage = {
  __typename?: 'PaginatedChatMessage';
  edges: Array<ChatMessageEdge>;
  pageInfo: PageInfo;
};

/** part  */
export type Part = {
  __typename?: 'Part';
  area?: Maybe<Scalars['String']['output']>;
  assets: Array<Asset>;
  cost?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  customFieldDateValues: Array<PartCustomFieldDateValue>;
  customFieldDatetimeValues: Array<PartCustomFieldDatetimeValue>;
  customFieldFloatValues: Array<PartCustomFieldFloatValue>;
  customFieldIntValues: Array<PartCustomFieldIntValue>;
  customFieldSelectValues: Array<PartCustomFieldSelectValue>;
  customFieldTextValues: Array<PartCustomFieldTextValue>;
  dependencyAssets: Array<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  documents: Array<PartDocument>;
  files: Array<PartFile>;
  groups: Array<Group>;
  id: Scalars['Int']['output'];
  manufacturer?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  office: Office;
  officeId: Scalars['Float']['output'];
  orderPoint?: Maybe<Scalars['Float']['output']>;
  qrBarCode?: Maybe<Scalars['String']['output']>;
  stock: Scalars['Float']['output'];
  stockChanges: Array<PartStockChange>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** 設備が依存している部品 */
export type PartAssetDependency = {
  __typename?: 'PartAssetDependency';
  asset: Asset;
  assetId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  part: DisplayPart;
  partId: Scalars['Float']['output'];
  requiredPartQuantity?: Maybe<Scalars['Float']['output']>;
};

/** PartCustomField */
export type PartCustomField = {
  __typename?: 'PartCustomField';
  customFieldFloatConfig?: Maybe<PartCustomFieldFloatConfig>;
  customFieldIntConfig?: Maybe<PartCustomFieldIntConfig>;
  customFieldSelectConfig?: Maybe<PartCustomFieldSelectConfig>;
  defaults: Array<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  label: Scalars['String']['output'];
  officeId: Scalars['Float']['output'];
  options: Array<PartCustomFieldOption>;
  type: PartCustomFieldTypeEnum;
};

/** Partのカスタムフィールド(Date)の値 */
export type PartCustomFieldDateValue = {
  __typename?: 'PartCustomFieldDateValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['DateTime']['output'];
};

export type PartCustomFieldDateValueInput = {
  customFieldId: Scalars['Float']['input'];
  value: Scalars['DateTime']['input'];
};

/** Partのカスタムフィールド(Datetime)の値 */
export type PartCustomFieldDatetimeValue = {
  __typename?: 'PartCustomFieldDatetimeValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['DateTime']['output'];
};

export type PartCustomFieldDatetimeValueInput = {
  customFieldId: Scalars['Float']['input'];
  value: Scalars['DateTime']['input'];
};

/** PartCustomFieldFloatConfig */
export type PartCustomFieldFloatConfig = {
  __typename?: 'PartCustomFieldFloatConfig';
  customFieldId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  interval?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

/** Partのカスタムフィールド(Float)の値 */
export type PartCustomFieldFloatValue = {
  __typename?: 'PartCustomFieldFloatValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type PartCustomFieldFloatValueInput = {
  customFieldId: Scalars['Float']['input'];
  value: Scalars['Float']['input'];
};

/** PartCustomFieldIntConfig */
export type PartCustomFieldIntConfig = {
  __typename?: 'PartCustomFieldIntConfig';
  customFieldId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  interval?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
};

/** Partのカスタムフィールド(Int)の値 */
export type PartCustomFieldIntValue = {
  __typename?: 'PartCustomFieldIntValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type PartCustomFieldIntValueInput = {
  customFieldId: Scalars['Float']['input'];
  value: Scalars['Int']['input'];
};

/** PartCustomFieldOption */
export type PartCustomFieldOption = {
  __typename?: 'PartCustomFieldOption';
  id: Scalars['Float']['output'];
  value: Scalars['String']['output'];
};

/** PartCustomFieldSelectConfig */
export type PartCustomFieldSelectConfig = {
  __typename?: 'PartCustomFieldSelectConfig';
  customFieldId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  multiple?: Maybe<Scalars['Boolean']['output']>;
};

/** Partのカスタムフィールド(Select)の値 */
export type PartCustomFieldSelectValue = {
  __typename?: 'PartCustomFieldSelectValue';
  customFieldId: Scalars['Int']['output'];
  optionId: Scalars['Int']['output'];
};

export type PartCustomFieldSelectValueInput = {
  customFieldId: Scalars['Float']['input'];
  optionId: Scalars['Int']['input'];
};

/** Partのカスタムフィールド(Text)の値 */
export type PartCustomFieldTextValue = {
  __typename?: 'PartCustomFieldTextValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type PartCustomFieldTextValueInput = {
  customFieldId: Scalars['Float']['input'];
  value: Scalars['String']['input'];
};

export const PartCustomFieldTypeEnum = {
  Date: 'date',
  Datetime: 'datetime',
  Float: 'float',
  Int: 'int',
  Select: 'select',
  Text: 'text',
} as const;

export type PartCustomFieldTypeEnum =
  (typeof PartCustomFieldTypeEnum)[keyof typeof PartCustomFieldTypeEnum];
/** PartのDocumentの値 */
export type PartDocument = {
  __typename?: 'PartDocument';
  contentType?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  partId: Scalars['Float']['output'];
  path: Scalars['String']['output'];
  size?: Maybe<Scalars['Int']['output']>;
  src?: Maybe<Scalars['String']['output']>;
  type: DocumentTypeEnum;
  url?: Maybe<Scalars['String']['output']>;
};

/** PartのFileの値 */
export type PartFile = {
  __typename?: 'PartFile';
  contentType: Scalars['String']['output'];
  fileId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partId: Scalars['Float']['output'];
  path: Scalars['String']['output'];
  src?: Maybe<Scalars['String']['output']>;
  thumbnailUploadedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** PartNotificationSetting */
export type PartNotificationSetting = {
  __typename?: 'PartNotificationSetting';
  replenishmentEnabled: Scalars['Boolean']['output'];
};

export type PartNotificationSettingInput = {
  replenishmentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Partの在庫変更履歴 */
export type PartStockChange = {
  __typename?: 'PartStockChange';
  newStock: Scalars['Float']['output'];
  newUsage?: Maybe<Scalars['Float']['output']>;
  prevStock: Scalars['Float']['output'];
  prevUsage?: Maybe<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatedBy?: Maybe<DisplayUser>;
  workOrder?: Maybe<DisplayWorkOrder>;
};

/** Part */
export type PartsWithPagination = {
  __typename?: 'PartsWithPagination';
  parts: Array<Part>;
  totalCount: Scalars['Float']['output'];
};

export type PatchCheckListCustomFieldCommandInput = {
  checkListId: Scalars['Int']['input'];
  customFieldId: Scalars['Int']['input'];
  dateValue?: InputMaybe<Scalars['LocalDate']['input']>;
  datetimeValue?: InputMaybe<Scalars['DateTime']['input']>;
  floatValue?: InputMaybe<Scalars['Float']['input']>;
  intValue?: InputMaybe<Scalars['Int']['input']>;
  selectOptionId?: InputMaybe<Scalars['Int']['input']>;
  textValue?: InputMaybe<Scalars['String']['input']>;
};

export type PatchCheckListCustomFieldNoteCommandInput = {
  checkListId: Scalars['Int']['input'];
  customFieldId: Scalars['Int']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type PatchWorkOrderCommandInput = {
  assetId?: InputMaybe<Scalars['Int']['input']>;
  assignees?: InputMaybe<Array<WorkOrderAssigneeInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  priority?: InputMaybe<WorkOrderPriority>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<WorkOrderStatus>;
  stoppage?: InputMaybe<WorkOrderStoppageInput>;
  stoppageReasonId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  workOrderId: Scalars['Int']['input'];
};

export type PatchWorkOrderCustomFieldCommandInput = {
  customFieldId: Scalars['Int']['input'];
  dateValue?: InputMaybe<Scalars['LocalDate']['input']>;
  datetimeValue?: InputMaybe<Scalars['DateTime']['input']>;
  floatValue?: InputMaybe<Scalars['Float']['input']>;
  intValue?: InputMaybe<Scalars['Int']['input']>;
  selectOptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  textValue?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
  workOrderId: Scalars['Int']['input'];
};

export type PivotDataV2 = {
  __typename?: 'PivotDataV2';
  columnSpecs: Array<PivotGroup>;
  rowSpecs: Array<PivotGroup>;
  series: Array<PivotDataV2ValueSeries>;
};

/** valueSpecぶんseriesができる (pivotテーブルの列数) */
export type PivotDataV2ValueSeries = {
  __typename?: 'PivotDataV2ValueSeries';
  valueSpec: PivotValueSpec;
  values: Array<PivotDataV2ValueSeriesValue>;
};

export type PivotDataV2ValueSeriesValue = {
  __typename?: 'PivotDataV2ValueSeriesValue';
  /** columnのそれぞれのindexに対応する値(日付やoptionのvalue値など。表示向け) */
  columnValues: Array<Scalars['String']['output']>;
  /** rowsのそれぞれのindexに対応する値(日付やoptionのvalue値など。表示向け) */
  rowValues: Array<Scalars['String']['output']>;
  value: Scalars['Float']['output'];
};

export type PivotGroup = {
  __typename?: 'PivotGroup';
  asset?: Maybe<PivotGroupAsset>;
  customField?: Maybe<PivotGroupCustomField>;
  type: PivotGroupType;
};

export type PivotGroupAsset = {
  __typename?: 'PivotGroupAsset';
  /** nullで最下層 */
  groupingDepth?: Maybe<Scalars['Int']['output']>;
};

export type PivotGroupAssetInput = {
  /** nullで最下層 */
  groupingDepth?: InputMaybe<Scalars['Int']['input']>;
};

export type PivotGroupCustomField = {
  __typename?: 'PivotGroupCustomField';
  customFieldId: Scalars['Int']['output'];
};

export type PivotGroupCustomFieldInput = {
  customFieldId: Scalars['Int']['input'];
};

export type PivotGroupInput = {
  asset?: InputMaybe<PivotGroupAssetInput>;
  customField?: InputMaybe<PivotGroupCustomFieldInput>;
  type: PivotGroupType;
};

export const PivotGroupType = {
  Asset: 'Asset',
  Assignee: 'Assignee',
  CustomField: 'CustomField',
  Group: 'Group',
  Product: 'Product',
  StoppageReason: 'StoppageReason',
  StoppageStartAtDate: 'StoppageStartAtDate',
  StoppageStartAtMonth: 'StoppageStartAtMonth',
  TaskCreatedAtDate: 'TaskCreatedAtDate',
  TaskCreatedAtMonth: 'TaskCreatedAtMonth',
} as const;

export type PivotGroupType =
  (typeof PivotGroupType)[keyof typeof PivotGroupType];
export type PivotValueSpec = {
  __typename?: 'PivotValueSpec';
  type: PivotValueType;
};

export type PivotValueSpecInput = {
  type: PivotValueType;
};

export const PivotValueType = {
  StoppageDuration: 'StoppageDuration',
  TaskCount: 'TaskCount',
} as const;

export type PivotValueType =
  (typeof PivotValueType)[keyof typeof PivotValueType];
/** Product */
export type Product = {
  __typename?: 'Product';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  officeId: Scalars['Int']['output'];
  parentProduct?: Maybe<Product>;
  unit?: Maybe<Scalars['String']['output']>;
};

/** project */
export type Project = {
  __typename?: 'Project';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  office?: Maybe<Office>;
  officeId: Scalars['Float']['output'];
};

export type Query = {
  __typename?: 'Query';
  accessPoliciesForCompany: Array<AccessPolicy>;
  asset: Asset;
  assetByQRcode: Asset;
  assetCustomFields: Array<AssetCustomField>;
  /** @deprecated use Asset.parts instead */
  assetDependentParts: Array<PartAssetDependency>;
  assetsWithPagination: AssetsWithPagination;
  chat: Chat;
  chatMessages: Array<ChatMessage>;
  chats: Array<Chat>;
  checkListCustomFields: Array<CheckListCustomField>;
  checkListTemplateV2: CheckListTemplate;
  checkListTemplatesV2: Array<CheckListTemplate>;
  checkLists: Array<CheckList>;
  companyPart: Part;
  companyParts: Array<Part>;
  companySetting: CompanySetting;
  countCompanyParts: Scalars['Int']['output'];
  countMyPendingWorkflowExecutions: Scalars['Int']['output'];
  countNotDoneWorkOrderByStatus: Array<CountNotDoneWorkOrderByStatus>;
  countParts: Scalars['Int']['output'];
  countWorkOrderByAsset: Array<CountWorkOrderByAsset>;
  countWorkOrderByAssignee: Array<CountWorkOrderByAssignee>;
  countWorkOrderByCreateDate: Array<CountWorkOrderByCreateDate>;
  countWorkOrderByCustomField: Array<CountWorkOrderByCustomField>;
  countWorkOrderByCustomFieldAndCreateDate: Array<CountWorkOrderByCustomFieldAndCreateDate>;
  countWorkOrderByStatus: Array<CountNotDoneWorkOrderByStatus>;
  countWorkOrderPart: CountWorkOrderPart;
  countWorkOrderPartByPart: Array<CountWorkOrderPartByPart>;
  currentOffice: Office;
  downloadableAssets: Array<Asset>;
  downloadableParts: Array<Part>;
  downloadableWorkOrders: Array<WorkOrder>;
  export: ExportResult;
  generateWorkOrderInputReference: WorkOrderInputReferenceData;
  group: Group;
  groups: Array<Group>;
  hierarchicalAssets: Array<HierarchicalAsset>;
  hierarchicalProducts: Array<HierarchicalProduct>;
  latestUpdatedDates: LatestUpdatedDates;
  /** ログイン前に叩く想定のため認証されません */
  loginMethodForEmailNonAuth: LoginMethodForEmail;
  loginMethodsForCompany: Array<LoginMethod>;
  me: User;
  notificationEvents: NotificationEvents;
  notifications: Notifications;
  part: Part;
  partCustomFields: Array<PartCustomField>;
  parts: Array<Part>;
  /** @deprecated Use parts instead */
  partsWithPagination: PartsWithPagination;
  pivotDataV2: PivotDataV2;
  product: Product;
  products: Array<Product>;
  recentlyAssignedWorkOrderUsers: Array<User>;
  recentlyUsedWorkOrderAssets: Array<Asset>;
  recentlyUsedWorkOrderProducts: Array<Product>;
  report: Report;
  reportTemplate: ReportTemplate;
  reportTemplates: Array<ReportTemplate>;
  reports: Array<Report>;
  request: Request;
  requestComments: RequestComments;
  requestTemplate: RequestTemplate;
  requestTemplates: Array<RequestTemplate>;
  requests: Requests;
  searchWorkOrders: Array<WorkOrder>;
  stoppageReasons: Array<StoppageReason>;
  users: Array<User>;
  validateImport: ImportValidationResult;
  workOrder: WorkOrder;
  workOrderComments: Array<WorkOrderComment>;
  workOrderCustomFields: Array<WorkOrderCustomField>;
  workOrderTemplateById: WorkOrderTemplate;
  workOrderTemplates: Array<WorkOrderTemplate>;
  workOrdersWithPagination: WorkOrdersWithPagination;
  workflowExecution: WorkflowExecution;
  workflowExecutions: Array<WorkflowExecution>;
};

export type QueryAssetArgs = {
  id: Scalars['Int']['input'];
};

export type QueryAssetByQRcodeArgs = {
  qrcode: Scalars['String']['input'];
};

export type QueryAssetCustomFieldsArgs = {
  officeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryAssetDependentPartsArgs = {
  id: Scalars['Int']['input'];
};

export type QueryAssetsWithPaginationArgs = {
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  customFieldOptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  officeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchField?: InputMaybe<Scalars['String']['input']>;
};

export type QueryChatArgs = {
  id: Scalars['Int']['input'];
};

export type QueryChatMessagesArgs = {
  chatId: Scalars['Float']['input'];
};

export type QueryCheckListTemplateV2Args = {
  id: Scalars['Int']['input'];
};

export type QueryCheckListTemplatesV2Args = {
  filterSpecs?: InputMaybe<Array<CheckListTemplateFilterArgs>>;
};

export type QueryCheckListsArgs = {
  checkListTemplateId: Scalars['Int']['input'];
  from: Scalars['String']['input'];
  includeSystemAdminCreated: Scalars['Boolean']['input'];
  to: Scalars['String']['input'];
};

export type QueryCompanyPartArgs = {
  id: Scalars['Int']['input'];
};

export type QueryCompanyPartsArgs = {
  limit: Scalars['Int']['input'];
  officeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchField?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCountCompanyPartsArgs = {
  officeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  searchField?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCountNotDoneWorkOrderByStatusArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  projectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCountPartsArgs = {
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  customFieldOptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  isReplenishment?: InputMaybe<Scalars['Boolean']['input']>;
  searchField?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCountWorkOrderByAssetArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  projectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCountWorkOrderByAssigneeArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  projectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCountWorkOrderByCreateDateArgs = {
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  assigneeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  from?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  optionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  projectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  stoppageReasonIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCountWorkOrderByCustomFieldArgs = {
  customFieldId: Scalars['Int']['input'];
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCountWorkOrderByCustomFieldAndCreateDateArgs = {
  customFieldId: Scalars['Int']['input'];
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCountWorkOrderByStatusArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  projectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCountWorkOrderPartArgs = {
  from: Scalars['String']['input'];
  projectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  to: Scalars['String']['input'];
};

export type QueryCountWorkOrderPartByPartArgs = {
  from: Scalars['String']['input'];
  projectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  to: Scalars['String']['input'];
};

export type QueryDownloadableWorkOrdersArgs = {
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  assigneeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  customFieldOptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  hasCheckList?: InputMaybe<Scalars['Boolean']['input']>;
  hasRequest?: InputMaybe<Scalars['Boolean']['input']>;
  isScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Scalars['String']['input']>;
  otherFilters?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  priorities?: InputMaybe<Array<Scalars['String']['input']>>;
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  projectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  searchField?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
  stoppageReasonIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  stoppageStartAtFrom?: InputMaybe<Scalars['String']['input']>;
  stoppageStartAtTo?: InputMaybe<Scalars['String']['input']>;
  templateIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type QueryExportArgs = {
  type: ImportType;
};

export type QueryGenerateWorkOrderInputReferenceArgs = {
  input: Scalars['String']['input'];
  templateId: Scalars['Int']['input'];
};

export type QueryGroupArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGroupsArgs = {
  officeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryHierarchicalAssetsArgs = {
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  customFieldOptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  officeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchField?: InputMaybe<Scalars['String']['input']>;
};

export type QueryHierarchicalProductsArgs = {
  officeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryLoginMethodForEmailNonAuthArgs = {
  email: Scalars['String']['input'];
};

export type QueryNotificationEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPartArgs = {
  id: Scalars['Int']['input'];
};

export type QueryPartCustomFieldsArgs = {
  officeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryPartsArgs = {
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  customFieldOptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  isReplenishment?: InputMaybe<Scalars['Boolean']['input']>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchField?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPartsWithPaginationArgs = {
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  customFieldOptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  isReplenishment?: InputMaybe<Scalars['Boolean']['input']>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchField?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPivotDataV2Args = {
  columnSpecs: Array<PivotGroupInput>;
  filterSpecs: Array<WorkOrderFilterInput>;
  rowSpecs: Array<PivotGroupInput>;
  valueSpecs: Array<PivotValueSpecInput>;
};

export type QueryProductArgs = {
  id: Scalars['Int']['input'];
};

export type QueryProductsArgs = {
  officeIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryRecentlyAssignedWorkOrderUsersArgs = {
  limit: Scalars['Int']['input'];
};

export type QueryRecentlyUsedWorkOrderAssetsArgs = {
  limit: Scalars['Int']['input'];
};

export type QueryRecentlyUsedWorkOrderProductsArgs = {
  limit: Scalars['Int']['input'];
};

export type QueryReportArgs = {
  id: Scalars['Int']['input'];
};

export type QueryReportTemplateArgs = {
  id: Scalars['Int']['input'];
};

export type QueryReportTemplatesArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryReportsArgs = {
  filters: Array<ReportFilterInput>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type QueryRequestArgs = {
  id: Scalars['Float']['input'];
};

export type QueryRequestCommentsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  requestId: Scalars['Float']['input'];
};

export type QueryRequestTemplateArgs = {
  id: Scalars['Float']['input'];
};

export type QueryRequestsArgs = {
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  customFieldOptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  from?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  projectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  searchField?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchWorkOrdersArgs = {
  filters: Array<WorkOrderFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy: Array<SearchWorkOrderSortByInput>;
};

export type QueryValidateImportArgs = {
  input: ImportPayloadInput;
};

export type QueryWorkOrderArgs = {
  id: Scalars['Float']['input'];
};

export type QueryWorkOrderCommentsArgs = {
  limit?: InputMaybe<Scalars['Float']['input']>;
  offset?: InputMaybe<Scalars['Float']['input']>;
  workOrderId: Scalars['Float']['input'];
};

export type QueryWorkOrderCustomFieldsArgs = {
  projectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryWorkOrderTemplateByIdArgs = {
  id: Scalars['Float']['input'];
};

export type QueryWorkOrderTemplatesArgs = {
  checkListTemplateIds?: InputMaybe<Scalars['Boolean']['input']>;
  projectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  useCheckList?: InputMaybe<Scalars['Boolean']['input']>;
  useDefault?: InputMaybe<Scalars['Boolean']['input']>;
  useRequest?: InputMaybe<Scalars['Boolean']['input']>;
  useSchedule?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryWorkOrdersWithPaginationArgs = {
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  assigneeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  createdByIds?: InputMaybe<Array<Scalars['String']['input']>>;
  customFieldOptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  dueDateFrom?: InputMaybe<Scalars['String']['input']>;
  dueDateTo?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  hasCheckList?: InputMaybe<Scalars['Boolean']['input']>;
  hasRequest?: InputMaybe<Scalars['Boolean']['input']>;
  isScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  ordering?: InputMaybe<Scalars['String']['input']>;
  otherFilters?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  priorities?: InputMaybe<Array<Scalars['String']['input']>>;
  productIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  projectIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  searchField?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
  stoppageReasonIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  stoppageStartAtFrom?: InputMaybe<Scalars['String']['input']>;
  stoppageStartAtTo?: InputMaybe<Scalars['String']['input']>;
  templateIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  to?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWorkflowExecutionArgs = {
  id: Scalars['Int']['input'];
};

export type QueryWorkflowExecutionsArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sortBy: Array<WorkflowExecutionsSearchSortBy>;
};

export type RemovePartAssetDependencyInput = {
  assetId: Scalars['Int']['input'];
  partId: Scalars['Int']['input'];
};

/** Report */
export type Report = HasComments & {
  __typename?: 'Report';
  comments: Array<Comment>;
  createdAt: Scalars['DateTime']['output'];
  firstVersion: ReportVersion;
  id: Scalars['Int']['output'];
  latestVersion: ReportVersion;
  reportTemplateVersion: ReportTemplateVersion;
  reportTemplateVersionId: Scalars['Int']['output'];
  workflowExecution: WorkflowExecution;
  workflowExecutionId: Scalars['Int']['output'];
};

export const ReportBaseDateType = {
  CreatedAt: 'createdAt',
  StoppageStartAt: 'stoppageStartAt',
} as const;

export type ReportBaseDateType =
  (typeof ReportBaseDateType)[keyof typeof ReportBaseDateType];
export type ReportFilterIdsInput = {
  ids: Array<Scalars['Int']['input']>;
};

export type ReportFilterInput = {
  template?: InputMaybe<ReportFilterIdsInput>;
  type: ReportFilterType;
};

export const ReportFilterType = {
  Template: 'Template',
} as const;

export type ReportFilterType =
  (typeof ReportFilterType)[keyof typeof ReportFilterType];
/** ReportLayout */
export type ReportLayout = {
  __typename?: 'ReportLayout';
  blocks: Array<ReportLayoutBlock>;
};

/** ReportLayoutBlock */
export type ReportLayoutBlock = {
  __typename?: 'ReportLayoutBlock';
  id: Scalars['String']['output'];
  table?: Maybe<ReportLayoutTableBlock>;
  text?: Maybe<ReportLayoutTextBlock>;
  type: ReportLayoutBlockType;
  workOrders?: Maybe<ReportLayoutWorkOrdersBlock>;
};

export type ReportLayoutBlockInput = {
  id: Scalars['String']['input'];
  table?: InputMaybe<ReportLayoutTableBlockInput>;
  text?: InputMaybe<ReportLayoutTextBlockInput>;
  type: ReportLayoutBlockType;
  workOrders?: InputMaybe<ReportLayoutWorkOrdersBlockInput>;
};

export const ReportLayoutBlockType = {
  Table: 'Table',
  Text: 'Text',
  WorkOrders: 'WorkOrders',
} as const;

export type ReportLayoutBlockType =
  (typeof ReportLayoutBlockType)[keyof typeof ReportLayoutBlockType];
export type ReportLayoutInput = {
  blocks: Array<ReportLayoutBlockInput>;
};

/** ReportLayoutTableBlock */
export type ReportLayoutTableBlock = {
  __typename?: 'ReportLayoutTableBlock';
  columns: Array<ReportLayoutTableBlockColumn>;
  defaultValues: Array<ReportVersionTableBlockRowDefaultValue>;
  rowExpandable: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

/** ReportLayoutTableBlockColumn */
export type ReportLayoutTableBlockColumn = {
  __typename?: 'ReportLayoutTableBlockColumn';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  select?: Maybe<ReportLayoutTableBlockSelectColumn>;
  text?: Maybe<ReportLayoutTableBlockTextColumn>;
  type: ReportLayoutTableBlockColumnType;
};

export type ReportLayoutTableBlockColumnInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  select?: InputMaybe<ReportLayoutTableBlockSelectColumnInput>;
  text?: InputMaybe<ReportLayoutTableBlockTextColumnInput>;
  type: ReportLayoutTableBlockColumnType;
};

export const ReportLayoutTableBlockColumnType = {
  DateTime: 'DateTime',
  Float: 'Float',
  Int: 'Int',
  Select: 'Select',
  Text: 'Text',
} as const;

export type ReportLayoutTableBlockColumnType =
  (typeof ReportLayoutTableBlockColumnType)[keyof typeof ReportLayoutTableBlockColumnType];
export type ReportLayoutTableBlockInput = {
  columns: Array<ReportLayoutTableBlockColumnInput>;
  defaultValues: Array<ReportVersionTableBlockRowDefaultValueInput>;
  rowExpandable: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

/** ReportLayoutTableBlockIntColumn */
export type ReportLayoutTableBlockSelectColumn = {
  __typename?: 'ReportLayoutTableBlockSelectColumn';
  options: Array<ReportLayoutTableBlockSelectColumnOption>;
};

export type ReportLayoutTableBlockSelectColumnInput = {
  options: Array<ReportLayoutTableBlockSelectColumnOptionInput>;
};

/** ReportLayoutTableBlockSelectColumnOption */
export type ReportLayoutTableBlockSelectColumnOption = {
  __typename?: 'ReportLayoutTableBlockSelectColumnOption';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ReportLayoutTableBlockSelectColumnOptionInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** ReportLayoutTableBlockIntColumn */
export type ReportLayoutTableBlockTextColumn = {
  __typename?: 'ReportLayoutTableBlockTextColumn';
  multiline: Scalars['Boolean']['output'];
};

export type ReportLayoutTableBlockTextColumnInput = {
  multiline: Scalars['Boolean']['input'];
};

/** ReportLayoutTextBlock */
export type ReportLayoutTextBlock = {
  __typename?: 'ReportLayoutTextBlock';
  lexicalValue: Scalars['String']['output'];
};

export type ReportLayoutTextBlockInput = {
  lexicalValue: Scalars['String']['input'];
};

/** ReportLayoutWorkOrdersBlock */
export type ReportLayoutWorkOrdersBlock = {
  __typename?: 'ReportLayoutWorkOrdersBlock';
  columns: Array<ReportLayoutWorkOrdersColumn>;
  defaultValue?: Maybe<ReportLayoutWorkOrdersBlockDefaultValue>;
  title: Scalars['String']['output'];
};

export const ReportLayoutWorkOrdersBlockColumnType = {
  Asset: 'Asset',
  Assignees: 'Assignees',
  CreatedAt: 'CreatedAt',
  CustomField: 'CustomField',
  DueDate: 'DueDate',
  Status: 'Status',
  StoppageEndAt: 'StoppageEndAt',
  StoppageReason: 'StoppageReason',
  StoppageStartAt: 'StoppageStartAt',
  Title: 'Title',
} as const;

export type ReportLayoutWorkOrdersBlockColumnType =
  (typeof ReportLayoutWorkOrdersBlockColumnType)[keyof typeof ReportLayoutWorkOrdersBlockColumnType];
/** ReportLayoutWorkOrdersBlockDefaultValue */
export type ReportLayoutWorkOrdersBlockDefaultValue = {
  __typename?: 'ReportLayoutWorkOrdersBlockDefaultValue';
  sortBy: Array<SearchWorkOrderSortBy>;
};

export type ReportLayoutWorkOrdersBlockDefaultValueInput = {
  sortBy: Array<SearchWorkOrderSortByInput>;
};

export type ReportLayoutWorkOrdersBlockInput = {
  columns: Array<ReportLayoutWorkOrdersColumnInput>;
  defaultValue?: InputMaybe<ReportLayoutWorkOrdersBlockDefaultValueInput>;
  title: Scalars['String']['input'];
};

/** ReportLayoutWorkOrdersColumn */
export type ReportLayoutWorkOrdersColumn = HasNullableWorkOrderCustomField & {
  __typename?: 'ReportLayoutWorkOrdersColumn';
  customField?: Maybe<WorkOrderCustomField>;
  customFieldId?: Maybe<Scalars['Int']['output']>;
  type: ReportLayoutWorkOrdersBlockColumnType;
};

export type ReportLayoutWorkOrdersColumnInput = {
  customFieldId?: InputMaybe<Scalars['Int']['input']>;
  type: ReportLayoutWorkOrdersBlockColumnType;
};

/** ReportTemplate */
export type ReportTemplate = {
  __typename?: 'ReportTemplate';
  id: Scalars['Int']['output'];
  latestVersion: ReportTemplateVersion;
  reports: Array<Report>;
  workflowTemplate: WorkflowTemplate;
  workflowTemplateId: Scalars['Int']['output'];
};

/** ReportTemplate */
export type ReportTemplateReportsArgs = {
  filters: Array<ReportFilterInput>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** ReportTemplateVersion */
export type ReportTemplateVersion = {
  __typename?: 'ReportTemplateVersion';
  id: Scalars['Int']['output'];
  layout: ReportLayout;
  name: Scalars['String']['output'];
  reportTemplateId: Scalars['Int']['output'];
};

/** ReportVersion */
export type ReportVersion = {
  __typename?: 'ReportVersion';
  blockValues: Array<ReportVersionBlockValue>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: User;
  createdById: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  reportId: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

/** ReportVersionBlockValue */
export type ReportVersionBlockValue = {
  __typename?: 'ReportVersionBlockValue';
  blockId: Scalars['String']['output'];
  table?: Maybe<ReportVersionTableBlockValue>;
  type: ReportLayoutBlockType;
  workOrders?: Maybe<ReportVersionWorkOrdersBlockValue>;
};

export type ReportVersionBlockValueInput = {
  blockId: Scalars['String']['input'];
  table?: InputMaybe<ReportVersionTableBlockValueInput>;
  type: ReportLayoutBlockType;
  workOrders?: InputMaybe<ReportVersionWorkOrdersBlockValueInput>;
};

/** ReportVersionTableBlockCellDefaultValue */
export type ReportVersionTableBlockCellDefaultValue = {
  __typename?: 'ReportVersionTableBlockCellDefaultValue';
  columnId: Scalars['String']['output'];
  dateTimeValue?: Maybe<Scalars['DateTime']['output']>;
  editable: Scalars['Boolean']['output'];
  floatValue?: Maybe<Scalars['Float']['output']>;
  intValue?: Maybe<Scalars['Int']['output']>;
  selectOptionId?: Maybe<Scalars['String']['output']>;
  textValue?: Maybe<Scalars['String']['output']>;
};

export type ReportVersionTableBlockCellDefaultValueInput = {
  columnId: Scalars['String']['input'];
  dateTimeValue?: InputMaybe<Scalars['DateTime']['input']>;
  editable: Scalars['Boolean']['input'];
  floatValue?: InputMaybe<Scalars['Float']['input']>;
  intValue?: InputMaybe<Scalars['Int']['input']>;
  selectOptionId?: InputMaybe<Scalars['String']['input']>;
  textValue?: InputMaybe<Scalars['String']['input']>;
};

/** ReportVersionTableBlockValue */
export type ReportVersionTableBlockCellValue = {
  __typename?: 'ReportVersionTableBlockCellValue';
  columnId: Scalars['String']['output'];
  dateTimeValue?: Maybe<Scalars['DateTime']['output']>;
  floatValue?: Maybe<Scalars['Float']['output']>;
  intValue?: Maybe<Scalars['Int']['output']>;
  selectOptionId?: Maybe<Scalars['String']['output']>;
  textValue?: Maybe<Scalars['String']['output']>;
};

export type ReportVersionTableBlockCellValueInput = {
  columnId: Scalars['String']['input'];
  dateTimeValue?: InputMaybe<Scalars['DateTime']['input']>;
  floatValue?: InputMaybe<Scalars['Float']['input']>;
  intValue?: InputMaybe<Scalars['Int']['input']>;
  selectOptionId?: InputMaybe<Scalars['String']['input']>;
  textValue?: InputMaybe<Scalars['String']['input']>;
};

/** ReportVersionTableBlockRowDefaultValue */
export type ReportVersionTableBlockRowDefaultValue = {
  __typename?: 'ReportVersionTableBlockRowDefaultValue';
  values: Array<ReportVersionTableBlockCellDefaultValue>;
};

export type ReportVersionTableBlockRowDefaultValueInput = {
  values: Array<ReportVersionTableBlockCellDefaultValueInput>;
};

/** ReportVersionTableBlockRowValue */
export type ReportVersionTableBlockRowValue = {
  __typename?: 'ReportVersionTableBlockRowValue';
  values: Array<ReportVersionTableBlockCellValue>;
};

export type ReportVersionTableBlockRowValueInput = {
  values: Array<ReportVersionTableBlockCellValueInput>;
};

/** ReportVersionTableBlockValue */
export type ReportVersionTableBlockValue = {
  __typename?: 'ReportVersionTableBlockValue';
  values: Array<ReportVersionTableBlockRowValue>;
};

export type ReportVersionTableBlockValueInput = {
  values: Array<ReportVersionTableBlockRowValueInput>;
};

/** ReportVersionWorkOrdersBlockValue */
export type ReportVersionWorkOrdersBlockValue = {
  __typename?: 'ReportVersionWorkOrdersBlockValue';
  workOrderIds: Array<Scalars['Int']['output']>;
  workOrders: Array<WorkOrder>;
};

export type ReportVersionWorkOrdersBlockValueInput = {
  workOrderIds: Array<Scalars['Int']['input']>;
};

/** Request */
export type Request = {
  __typename?: 'Request';
  asset?: Maybe<Asset>;
  assetId?: Maybe<Scalars['Int']['output']>;
  assignees: Array<DisplayUser>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: DisplayUser;
  customFieldDateValues: Array<RequestCustomFieldDateValue>;
  customFieldDatetimeValues: Array<RequestCustomFieldDatetimeValue>;
  customFieldFileValues: Array<RequestCustomFieldFileValue>;
  customFieldFloatValues: Array<RequestCustomFieldFloatValue>;
  customFieldIntValues: Array<RequestCustomFieldIntValue>;
  customFieldSelectValues: Array<RequestCustomFieldSelectValue>;
  customFieldTextValues: Array<RequestCustomFieldTextValue>;
  customFieldUserValues: Array<RequestCustomFieldUserValue>;
  description?: Maybe<Scalars['String']['output']>;
  groups: Array<Group>;
  id: Scalars['Float']['output'];
  priority: RequestPriority;
  projectId: Scalars['Float']['output'];
  status: RequestStatus;
  statusHistories: Array<RequestStatusHistory>;
  stoppage?: Maybe<RequestStoppage>;
  stoppageReason?: Maybe<StoppageReason>;
  template: RequestTemplate;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: DisplayUser;
  workOrders: Array<DisplayWorkOrder>;
};

/** Requestに紐づいて使われる担当者のID */
export type RequestAssigneeInput = {
  userId: Scalars['String']['input'];
};

/** Requestに紐づいてるコメント */
export type RequestComment = {
  __typename?: 'RequestComment';
  attachments: Array<RequestCommentAttachment>;
  comment: Scalars['String']['output'];
  /** LexicalでフォーマットされたJsonをStringで返す */
  commentLexical?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  isEdited: Scalars['Boolean']['output'];
  mentionIds: Array<Scalars['String']['output']>;
  replyComment?: Maybe<BaseRequestComment>;
  replyCommentId?: Maybe<Scalars['Int']['output']>;
  requestId: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: DisplayUser;
};

/** Request comment attachment */
export type RequestCommentAttachment = {
  __typename?: 'RequestCommentAttachment';
  contentType: Scalars['String']['output'];
  fileId: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  requestCommentId: Scalars['Float']['output'];
};

export type RequestCommentAttachmentInput = {
  contentType: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Request comment subscription event */
export type RequestCommentSubscriptionEvent = {
  __typename?: 'RequestCommentSubscriptionEvent';
  commentId: Scalars['Float']['output'];
  eventType: Scalars['String']['output'];
  requestComment?: Maybe<RequestComment>;
  requestId: Scalars['Float']['output'];
};

/** RequestComments */
export type RequestComments = {
  __typename?: 'RequestComments';
  requestComments: Array<RequestComment>;
  totalCount: Scalars['Float']['output'];
};

/** Requestのカスタムフィールド(日付)の値 */
export type RequestCustomFieldDateValue = {
  __typename?: 'RequestCustomFieldDateValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['DateTime']['output'];
};

export type RequestCustomFieldDateValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['DateTime']['input'];
};

/** Requestのカスタムフィールド(日時)の値 */
export type RequestCustomFieldDatetimeValue = {
  __typename?: 'RequestCustomFieldDatetimeValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['DateTime']['output'];
};

export type RequestCustomFieldDatetimeValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['DateTime']['input'];
};

/** Requestのカスタムフィールド(ファイル)の値 */
export type RequestCustomFieldFileValue = {
  __typename?: 'RequestCustomFieldFileValue';
  contentType: Scalars['String']['output'];
  customFieldId: Scalars['Int']['output'];
  fileId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  src: Scalars['String']['output'];
};

export type RequestCustomFieldFileValueInput = {
  contentType: Scalars['String']['input'];
  customFieldId: Scalars['Int']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** Requestのカスタムフィールド(Float)の値 */
export type RequestCustomFieldFloatValue = {
  __typename?: 'RequestCustomFieldFloatValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type RequestCustomFieldFloatValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};

/** Requestのカスタムフィールド(Int)の値 */
export type RequestCustomFieldIntValue = {
  __typename?: 'RequestCustomFieldIntValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type RequestCustomFieldIntValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['Int']['input'];
};

/** Requestのカスタムフィールド(選択)の値 */
export type RequestCustomFieldSelectValue = {
  __typename?: 'RequestCustomFieldSelectValue';
  categoryName?: Maybe<Scalars['String']['output']>;
  customFieldId: Scalars['Int']['output'];
  optionId: Scalars['Float']['output'];
  value: Scalars['String']['output'];
};

export type RequestCustomFieldSelectValueInput = {
  customFieldId: Scalars['Int']['input'];
  optionId: Scalars['Float']['input'];
};

/** Requestのカスタムフィールド(テキスト)の値 */
export type RequestCustomFieldTextValue = {
  __typename?: 'RequestCustomFieldTextValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type RequestCustomFieldTextValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

/** Requestのカスタムフィールド(ユーザー)の値 */
export type RequestCustomFieldUserValue = {
  __typename?: 'RequestCustomFieldUserValue';
  customFieldId: Scalars['Int']['output'];
  user: DisplayUser;
};

export type RequestCustomFieldUserValueInput = {
  customFieldId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

/** RequestNotificationSetting */
export type RequestNotificationSetting = {
  __typename?: 'RequestNotificationSetting';
  requestApproveEnabled: Scalars['Boolean']['output'];
  requestAssignToGroupEnabled: Scalars['Boolean']['output'];
  requestAssignToGroupMemberEnabled: Scalars['Boolean']['output'];
  requestCreateEnabled: Scalars['Boolean']['output'];
  requestRejectEnabled: Scalars['Boolean']['output'];
  requestWithdrawnEnabled: Scalars['Boolean']['output'];
  requestedWorkOrderCompleteEnabled: Scalars['Boolean']['output'];
};

export type RequestNotificationSettingInput = {
  requestApproveEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  requestAssignToGroupEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  requestAssignToGroupMemberEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  requestCreateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  requestRejectEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  requestWithdrawnEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  requestedWorkOrderCompleteEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  sameGroupAssignCreateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export const RequestPriority = {
  High: 'high',
  Low: 'low',
  Medium: 'medium',
  None: 'none',
} as const;

export type RequestPriority =
  (typeof RequestPriority)[keyof typeof RequestPriority];
export const RequestStatus = {
  Approved: 'approved',
  Canceled: 'canceled',
  Pending: 'pending',
  Rejected: 'rejected',
} as const;

export type RequestStatus = (typeof RequestStatus)[keyof typeof RequestStatus];
/** RequestStatusHistory */
export type RequestStatusHistory = {
  __typename?: 'RequestStatusHistory';
  id: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: DisplayUser;
};

/** Request の停止についての項目 */
export type RequestStoppage = {
  __typename?: 'RequestStoppage';
  endAt?: Maybe<Scalars['DateTime']['output']>;
  startAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Request の停止についての項目 */
export type RequestStoppageInput = {
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** RequestTemplate */
export type RequestTemplate = {
  __typename?: 'RequestTemplate';
  assetEnabled: Scalars['Boolean']['output'];
  assigneeEnabled: Scalars['Boolean']['output'];
  customFields: Array<WorkOrderCustomField>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionEnabled: Scalars['Boolean']['output'];
  fieldOrders: Array<RequestTemplateFieldOrder>;
  groupEnabled: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  priorityEnabled: Scalars['Boolean']['output'];
  projectId: Scalars['Float']['output'];
  titleEnabled: Scalars['Boolean']['output'];
};

/** RequestTemplateFieldOrder */
export type RequestTemplateFieldOrder = {
  __typename?: 'RequestTemplateFieldOrder';
  customFieldId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Float']['output'];
  order: Scalars['Int']['output'];
  templateId: Scalars['Int']['output'];
  type: RequestTemplateFieldOrderType;
};

export type RequestTemplateFieldOrderInput = {
  customFieldId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Float']['input'];
  type: RequestTemplateFieldOrderType;
};

export const RequestTemplateFieldOrderType = {
  Asset: 'asset',
  Assignee: 'assignee',
  CustomField: 'customField',
  Description: 'description',
  Group: 'group',
  Priority: 'priority',
  Stoppage: 'stoppage',
  StoppageReason: 'stoppageReason',
  Title: 'title',
} as const;

export type RequestTemplateFieldOrderType =
  (typeof RequestTemplateFieldOrderType)[keyof typeof RequestTemplateFieldOrderType];
/** Requests */
export type Requests = {
  __typename?: 'Requests';
  requests: Array<Request>;
  totalCount: Scalars['Float']['output'];
};

/** roles */
export type Role = {
  __typename?: 'Role';
  department: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  position: Scalars['String']['output'];
};

export type ScheduleDueDateInput = {
  date: Scalars['DateTime']['input'];
};

export type ScheduleMonthlyDateInput = {
  date: Scalars['DateTime']['input'];
  monthlyId: Scalars['Int']['input'];
};

export type ScheduleMonthlyDayOfWeekDateInput = {
  date: Scalars['DateTime']['input'];
  monthlyDayOfWeekId: Scalars['Int']['input'];
};

export type ScheduleWeeklyDateInput = {
  date: Scalars['DateTime']['input'];
  weeklyId: Scalars['Int']['input'];
};

export type SearchWorkOrderSortBy = {
  __typename?: 'SearchWorkOrderSortBy';
  order: Order;
  type: WorkOrderSortByType;
};

export type SearchWorkOrderSortByInput = {
  order: Order;
  type: WorkOrderSortByType;
};

/** StoppageReason */
export type StoppageReason = {
  __typename?: 'StoppageReason';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  officeId: Scalars['Int']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  chatEvent: ChatSubscriptionEvent;
  chatMessageEvent: ChatMessageSubscriptionEvent;
  requestCommentEvent: RequestCommentSubscriptionEvent;
  workOrderCommentEvent: WorkOrderCommentSubscriptionEvent;
};

export type SubscriptionChatEventArgs = {
  userId: Scalars['String']['input'];
};

export type SubscriptionChatMessageEventArgs = {
  chatId: Scalars['Float']['input'];
};

export type SubscriptionRequestCommentEventArgs = {
  requestId: Scalars['Float']['input'];
};

export type SubscriptionWorkOrderCommentEventArgs = {
  workOrderId: Scalars['Float']['input'];
};

/** Suggest */
export type Suggest = {
  __typename?: 'Suggest';
  workOrderTextModel?: Maybe<Scalars['String']['output']>;
  workOrderTextPrompt?: Maybe<Scalars['String']['output']>;
  workOrderTextTemperature?: Maybe<Scalars['Float']['output']>;
};

export type SuggestWorkOrderTextInput = {
  data: Scalars['String']['input'];
};

export type UpdateAssetInput = {
  assetDocuments?: InputMaybe<Array<NewAssetFileInput>>;
  controlNumber?: InputMaybe<Scalars['String']['input']>;
  customFieldDateValues?: InputMaybe<Array<AssetCustomFieldDateValueInput>>;
  customFieldDatetimeValues?: InputMaybe<
    Array<AssetCustomFieldDatetimeValueInput>
  >;
  customFieldFloatValues?: InputMaybe<Array<AssetCustomFieldFloatValueInput>>;
  customFieldIntValues?: InputMaybe<Array<AssetCustomFieldIntValueInput>>;
  customFieldSelectValues?: InputMaybe<Array<AssetCustomFieldSelectValueInput>>;
  customFieldTextValues?: InputMaybe<Array<AssetCustomFieldTextValueInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['Float']['input'];
  manufactureDate?: InputMaybe<Scalars['DateTime']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['Float']['input']>;
  parentAssetId?: InputMaybe<Scalars['Float']['input']>;
  qrBarCode?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  stoppageReasonIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateChatInput = {
  deletedUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateChatMessageInput = {
  attachments?: InputMaybe<Array<ChatMessageAttachmentInput>>;
  deletedAttachmentFileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Int']['input'];
  mentionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  message: Scalars['String']['input'];
  /** LexicalでフォーマットされたJsonのメッセージ */
  messageLexical: Scalars['String']['input'];
  /** Pain Textのメッセージ */
  messagePlainText: Scalars['String']['input'];
  replyMessageId?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCheckListCustomFieldAttachmentCommandInput = {
  attachmentId: Scalars['Int']['input'];
  checkListId: Scalars['Int']['input'];
  contentType: Scalars['String']['input'];
  customFieldId: Scalars['Float']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** CheckListに紐づいてるチェックリストの更新 */
export type UpdateCheckListInput = {
  customFieldAttachments?: InputMaybe<
    Array<CheckListCustomFieldAttachmentInput>
  >;
  customFieldDateValues?: InputMaybe<Array<CheckListCustomFieldDateValueInput>>;
  customFieldDatetimeValues?: InputMaybe<
    Array<CheckListCustomFieldDatetimeValueInput>
  >;
  customFieldFloatValues?: InputMaybe<
    Array<CheckListCustomFieldFloatValueInput>
  >;
  customFieldIntValues?: InputMaybe<Array<CheckListCustomFieldIntValueInput>>;
  customFieldNotes?: InputMaybe<Array<CheckListCustomFieldNoteInput>>;
  customFieldSelectValues?: InputMaybe<
    Array<CheckListCustomFieldSelectValueInput>
  >;
  customFieldStampValues?: InputMaybe<
    Array<CheckListCustomFieldStampValueInput>
  >;
  customFieldTextValues?: InputMaybe<Array<CheckListCustomFieldTextValueInput>>;
  id: Scalars['Float']['input'];
  newCustomFields?: InputMaybe<Array<CheckListCustomFieldInputForNewTemplate>>;
  newSections?: InputMaybe<Array<NewCheckListTemplateSectionInput>>;
};

export type UpdateCheckListTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  newCustomFields: Array<CheckListCustomFieldInputForNewTemplate>;
  newSections: Array<NewCheckListTemplateSectionInput>;
  workOrderTemplate: WorkOrderTemplateForCheckListInput;
};

export type UpdateCheckListTemplateLockInput = {
  id: Scalars['Float']['input'];
  isIndividualExecutionLocked: Scalars['Boolean']['input'];
};

export type UpdateGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  officeId?: InputMaybe<Scalars['Int']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateMeInput = {
  locale?: InputMaybe<Scalars['String']['input']>;
  setting?: InputMaybe<UpdateSettingInput>;
};

export type UpdateOfficeInput = {
  currencyCodes: Array<CurrencyCodeEnum>;
};

export type UpdatePartAssetDependencyInput = {
  assetId: Scalars['Int']['input'];
  partId: Scalars['Int']['input'];
  requiredPartQuantity?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePartInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  assetIds: Array<Scalars['Int']['input']>;
  cost?: InputMaybe<Scalars['Float']['input']>;
  currencyCode?: InputMaybe<CurrencyCodeEnum>;
  customFieldDateValues?: InputMaybe<Array<PartCustomFieldDateValueInput>>;
  customFieldDatetimeValues?: InputMaybe<
    Array<PartCustomFieldDatetimeValueInput>
  >;
  customFieldFloatValues?: InputMaybe<Array<PartCustomFieldFloatValueInput>>;
  customFieldIntValues?: InputMaybe<Array<PartCustomFieldIntValueInput>>;
  customFieldSelectValues?: InputMaybe<Array<PartCustomFieldSelectValueInput>>;
  customFieldTextValues?: InputMaybe<Array<PartCustomFieldTextValueInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['Float']['input'];
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orderPoint?: InputMaybe<Scalars['Float']['input']>;
  qrBarCode?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePartStockInput = {
  id: Scalars['Float']['input'];
  replenishment: Scalars['Float']['input'];
  stock: Scalars['Float']['input'];
};

export type UpdateProductInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  parentProductId?: InputMaybe<Scalars['Int']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReportInput = {
  blockValues: Array<ReportVersionBlockValueInput>;
  id: Scalars['Int']['input'];
  reportTemplateVersionId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type UpdateReportTemplateInput = {
  id: Scalars['Int']['input'];
  layout: ReportLayoutInput;
  name: Scalars['String']['input'];
  workflow: WorkflowDefinitionInput;
};

export type UpdateRequestCommentInput = {
  attachments?: InputMaybe<Array<RequestCommentAttachmentInput>>;
  comment: Scalars['String']['input'];
  commentLexical?: InputMaybe<Scalars['String']['input']>;
  deletedAttachmentFileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Float']['input'];
  mentionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateRequestCustomFieldFileInput = {
  contentType: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  valueId: Scalars['Float']['input'];
};

export type UpdateRequestInput = {
  assetId?: InputMaybe<Scalars['Float']['input']>;
  assignees?: InputMaybe<Array<RequestAssigneeInput>>;
  comment?: InputMaybe<NewRequestCommentInput>;
  customFieldDateValues?: InputMaybe<Array<RequestCustomFieldDateValueInput>>;
  customFieldDatetimeValues?: InputMaybe<
    Array<RequestCustomFieldDatetimeValueInput>
  >;
  customFieldFileValues?: InputMaybe<Array<RequestCustomFieldFileValueInput>>;
  customFieldFloatValues?: InputMaybe<Array<RequestCustomFieldFloatValueInput>>;
  customFieldIntValues?: InputMaybe<Array<RequestCustomFieldIntValueInput>>;
  customFieldSelectValues?: InputMaybe<
    Array<RequestCustomFieldSelectValueInput>
  >;
  customFieldTextValues?: InputMaybe<Array<RequestCustomFieldTextValueInput>>;
  customFieldUserValues?: InputMaybe<Array<RequestCustomFieldUserValueInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['Float']['input'];
  priority?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  stoppage?: InputMaybe<RequestStoppageInput>;
  stoppageReasonId?: InputMaybe<Scalars['Int']['input']>;
  templateId?: InputMaybe<Scalars['Float']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  workOrder?: InputMaybe<NewWorkOrderInput>;
};

export type UpdateRequestTemplateInput = {
  assetEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  assigneeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  fieldOrders?: InputMaybe<Array<RequestTemplateFieldOrderInput>>;
  groupEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  priorityEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  titleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateSettingInput = {
  chatNotificationSetting?: InputMaybe<ChatNotificationSettingInput>;
  checkListNotificationSetting?: InputMaybe<CheckListNotificationSettingInput>;
  partNotificationSetting?: InputMaybe<PartNotificationSettingInput>;
  requestNotificationSetting?: InputMaybe<RequestNotificationSettingInput>;
  workOrderNotificationSetting?: InputMaybe<WorkOrderNotificationSettingInput>;
};

export type UpdateUserInput = {
  accessPolicyId?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  loginMethodId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  oldPosition?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['String']['input'];
};

/** WorkOrderに紐づいてるコメント */
export type UpdateWorkOrderCommentInput = {
  attachments?: InputMaybe<Array<WorkOrderCommentAttachmentInput>>;
  comment: Scalars['String']['input'];
  /** LexicalでフォーマットされたJsonをStringで返す */
  commentLexical?: InputMaybe<Scalars['String']['input']>;
  deletedAttachmentFileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Float']['input'];
  mentionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateWorkOrderCustomFieldFileCommandInput = {
  contentType: Scalars['String']['input'];
  customFieldId: Scalars['Int']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  valueId: Scalars['Int']['input'];
  workOrderId: Scalars['Int']['input'];
};

export type UpdateWorkOrderCustomFieldFileInput = {
  contentType: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  valueId: Scalars['Float']['input'];
};

export type UpdateWorkOrderCustomFieldInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionLexical?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  label: Scalars['String']['input'];
  multiple?: InputMaybe<Scalars['Boolean']['input']>;
  options?: InputMaybe<Array<UpdateWorkOrderCustomFieldOption>>;
  rows?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
};

export type UpdateWorkOrderCustomFieldOption = {
  id?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};

export type UpdateWorkOrderInput = {
  assetId?: InputMaybe<Scalars['Float']['input']>;
  assignees?: InputMaybe<Array<WorkOrderAssigneeInput>>;
  checkLists?: InputMaybe<Array<UpdateCheckListInput>>;
  customFieldDateValues?: InputMaybe<Array<WorkOrderCustomFieldDateValueInput>>;
  customFieldDatetimeValues?: InputMaybe<
    Array<WorkOrderCustomFieldDatetimeValueInput>
  >;
  customFieldFileValues?: InputMaybe<Array<WorkOrderCustomFieldFileValueInput>>;
  customFieldFloatValues?: InputMaybe<
    Array<WorkOrderCustomFieldFloatValueInput>
  >;
  customFieldIntValues?: InputMaybe<Array<WorkOrderCustomFieldIntValueInput>>;
  customFieldSelectValues?: InputMaybe<
    Array<WorkOrderCustomFieldSelectValueInput>
  >;
  customFieldTextValues?: InputMaybe<Array<WorkOrderCustomFieldTextValueInput>>;
  customFieldUserValues?: InputMaybe<Array<WorkOrderCustomFieldUserValueInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  groupIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['Float']['input'];
  parts?: InputMaybe<Array<WorkOrderPartInput>>;
  priority?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  stoppage?: InputMaybe<WorkOrderStoppageInput>;
  stoppageReasonId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkOrderPartCommandInput = {
  partId: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  workOrderId: Scalars['Int']['input'];
};

export type UpdateWorkOrderPartInput = {
  partId: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  workOrderId: Scalars['Float']['input'];
};

export type UpdateWorkOrderTemplateInput = {
  assetEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  assetId?: InputMaybe<Scalars['Float']['input']>;
  assigneeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  checkListEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  customFields?: InputMaybe<Array<WorkOrderCustomFieldInput>>;
  defaultAssignees?: InputMaybe<Array<WorkOrderTemplateDefaultAssigneeInput>>;
  defaultCheckListTemplates?: InputMaybe<
    Array<WorkOrderTemplateDefaultCheckListTemplate>
  >;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  dueEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  fieldOrders?: InputMaybe<Array<WorkOrderTemplateFieldOrderInput>>;
  groupEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  isScheduleRenewed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  partEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  priorityEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  productEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  scheduleDueDates?: InputMaybe<Array<WorkOrderTemplateScheduleDueDateInput>>;
  scheduleMonthlies?: InputMaybe<Array<WorkOrderTemplateScheduleMonthlyInput>>;
  scheduleMonthlyDayOfWeeks?: InputMaybe<
    Array<WorkOrderTemplateScheduleMonthlyDayOfWeekInput>
  >;
  scheduleWeeklies?: InputMaybe<Array<WorkOrderTemplateScheduleWeeklyInput>>;
  stoppageEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  titleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** user */
export type User = {
  __typename?: 'User';
  accessPolicy?: Maybe<AccessPolicy>;
  channelTalkMemberHash: Scalars['String']['output'];
  company: Company;
  companyId: Scalars['Float']['output'];
  companyRoles: Array<UserCompanyRole>;
  email: Scalars['String']['output'];
  groups: Array<Group>;
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  loginMethod?: Maybe<LoginMethod>;
  name: Scalars['String']['output'];
  newNotificationCount: Scalars['Int']['output'];
  officeRoles: Array<UserOfficeRole>;
  offices: Array<UserOffice>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  projectRoles: Array<UserProjectRole>;
  setting: UserSetting;
};

/** user company role */
export type UserCompanyRole = {
  __typename?: 'UserCompanyRole';
  company: Company;
  companyId: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  role: Role;
  roleId: Scalars['Float']['output'];
  userId: Scalars['String']['output'];
};

/** Office which user belongs to */
export type UserOffice = {
  __typename?: 'UserOffice';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  projects: Array<UserProject>;
};

/** user office role */
export type UserOfficeRole = {
  __typename?: 'UserOfficeRole';
  id: Scalars['ID']['output'];
  office: Office;
  officeId: Scalars['Float']['output'];
  role: Role;
  roleId: Scalars['Float']['output'];
  userId: Scalars['String']['output'];
};

/** Project which user belongs to */
export type UserProject = {
  __typename?: 'UserProject';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** user project role */
export type UserProjectRole = {
  __typename?: 'UserProjectRole';
  id: Scalars['ID']['output'];
  project: Project;
  projectId: Scalars['Float']['output'];
  role: Role;
  roleId: Scalars['Float']['output'];
  userId: Scalars['String']['output'];
};

/** UserSetting */
export type UserSetting = {
  __typename?: 'UserSetting';
  chatNotificationSetting?: Maybe<ChatNotificationSetting>;
  checkListNotificationSetting?: Maybe<CheckListNotificationSetting>;
  partNotificationSetting?: Maybe<PartNotificationSetting>;
  requestNotificationSetting?: Maybe<RequestNotificationSetting>;
  workOrderNotificationSetting?: Maybe<WorkOrderNotificationSetting>;
};

/** WorkOrder */
export type WorkOrder = {
  __typename?: 'WorkOrder';
  asset?: Maybe<Asset>;
  assetId?: Maybe<Scalars['Int']['output']>;
  assignees: Array<DisplayUser>;
  checkLists: Array<CheckList>;
  commentCount: Scalars['Float']['output'];
  countWorkOrderLinks: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: DisplayUser;
  customFieldDateValues: Array<WorkOrderCustomFieldDateValue>;
  customFieldDatetimeValues: Array<WorkOrderCustomFieldDatetimeValue>;
  customFieldFileValues: Array<WorkOrderCustomFieldFileValue>;
  customFieldFloatValues: Array<WorkOrderCustomFieldFloatValue>;
  customFieldIntValues: Array<WorkOrderCustomFieldIntValue>;
  customFieldSelectValues: Array<WorkOrderCustomFieldSelectValue>;
  customFieldTextValues: Array<WorkOrderCustomFieldTextValue>;
  customFieldUserValues: Array<WorkOrderCustomFieldUserValue>;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated 一旦、groupはタスクから削除 */
  groups: Array<Group>;
  id: Scalars['Float']['output'];
  inboundLinks: Array<WorkOrderLink>;
  isScheduled: Scalars['Boolean']['output'];
  outboundLinks: Array<WorkOrderLink>;
  parts: Array<WorkOrderPart>;
  priority: WorkOrderPriority;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']['output']>;
  projectId: Scalars['Float']['output'];
  requestIds: Array<Scalars['Int']['output']>;
  requests: Array<DisplayRequest>;
  status: WorkOrderStatus;
  stoppage?: Maybe<WorkOrderStoppage>;
  stoppageReason?: Maybe<StoppageReason>;
  template: WorkOrderTemplate;
  templateId?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  updatedBy: DisplayUser;
};

/** WorkOrderに紐づいて使われる担当者のID */
export type WorkOrderAssigneeInput = {
  userId: Scalars['String']['input'];
};

/** WorkOrderに紐づいてるコメント */
export type WorkOrderBaseComment = {
  __typename?: 'WorkOrderBaseComment';
  comment: Scalars['String']['output'];
  /** LexicalでフォーマットされたJsonをStringで返す */
  commentLexical?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  isEdited: Scalars['Boolean']['output'];
  replyCommentId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: DisplayUser;
  workOrderId: Scalars['Float']['output'];
};

/** WorkOrderに紐づいてるコメント */
export type WorkOrderComment = {
  __typename?: 'WorkOrderComment';
  attachments: Array<WorkOrderCommentAttachment>;
  comment: Scalars['String']['output'];
  /** LexicalでフォーマットされたJsonをStringで返す */
  commentLexical?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  isEdited: Scalars['Boolean']['output'];
  mentionIds: Array<Scalars['String']['output']>;
  replyComment?: Maybe<WorkOrderBaseComment>;
  replyCommentId?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: DisplayUser;
  workOrderId: Scalars['Float']['output'];
};

/** WorkOrder comment attachment */
export type WorkOrderCommentAttachment = {
  __typename?: 'WorkOrderCommentAttachment';
  contentType: Scalars['String']['output'];
  fileId: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  workOrderCommentId: Scalars['Float']['output'];
};

/** Work order comment attachment */
export type WorkOrderCommentAttachmentInput = {
  contentType: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** WorkOrder comment subscription event */
export type WorkOrderCommentSubscriptionEvent = {
  __typename?: 'WorkOrderCommentSubscriptionEvent';
  commentId: Scalars['Float']['output'];
  eventType: Scalars['String']['output'];
  workOrderComment?: Maybe<WorkOrderComment>;
  workOrderId: Scalars['Float']['output'];
};

/** WorkOrderCustomField */
export type WorkOrderCustomField = {
  __typename?: 'WorkOrderCustomField';
  customFieldFloatConfig?: Maybe<WorkOrderCustomFieldFloatConfig>;
  customFieldIntConfig?: Maybe<WorkOrderCustomFieldIntConfig>;
  customFieldSelectConfig?: Maybe<WorkOrderCustomFieldSelectConfig>;
  customFieldTextConfig?: Maybe<WorkOrderCustomFieldTextConfig>;
  defaults: Array<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  label: Scalars['String']['output'];
  /** @deprecated selectItemsを使うようにする */
  options: Array<WorkOrderCustomFieldOption>;
  projectId: Scalars['Float']['output'];
  selectItems: Array<WorkOrderCustomFieldSelectItem>;
  type: WorkOrderCustomFieldType;
};

/** WorkOrderのカスタムフィールド日付の値 */
export type WorkOrderCustomFieldDateValue = {
  __typename?: 'WorkOrderCustomFieldDateValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['DateTime']['output'];
};

export type WorkOrderCustomFieldDateValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['DateTime']['input'];
};

/** WorkOrderのカスタムフィールド日時の値 */
export type WorkOrderCustomFieldDatetimeValue = {
  __typename?: 'WorkOrderCustomFieldDatetimeValue';
  customFieldId: Scalars['Int']['output'];
  /** @deprecated この値は使用されていない。client側が対応したら削除する。 */
  remark?: Maybe<Scalars['String']['output']>;
  value: Scalars['DateTime']['output'];
};

export type WorkOrderCustomFieldDatetimeValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['DateTime']['input'];
};

/** WorkOrderのカスタムフィールド(File)の値 */
export type WorkOrderCustomFieldFileValue = {
  __typename?: 'WorkOrderCustomFieldFileValue';
  contentType: Scalars['String']['output'];
  customFieldId: Scalars['Int']['output'];
  fileId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  src: Scalars['String']['output'];
};

export type WorkOrderCustomFieldFileValueInput = {
  contentType: Scalars['String']['input'];
  customFieldId: Scalars['Float']['input'];
  fileId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** WorkOrderCustomFieldFloatConfig */
export type WorkOrderCustomFieldFloatConfig = {
  __typename?: 'WorkOrderCustomFieldFloatConfig';
  id: Scalars['Int']['output'];
  interval?: Maybe<Scalars['Float']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

/** WorkOrderのカスタムフィールド(Float)の値 */
export type WorkOrderCustomFieldFloatValue = {
  __typename?: 'WorkOrderCustomFieldFloatValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type WorkOrderCustomFieldFloatValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};

export type WorkOrderCustomFieldInput = {
  id: Scalars['Int']['input'];
  order: Scalars['Int']['input'];
};

/** WorkOrderCustomFieldIntConfig */
export type WorkOrderCustomFieldIntConfig = {
  __typename?: 'WorkOrderCustomFieldIntConfig';
  id: Scalars['Int']['output'];
  interval?: Maybe<Scalars['Int']['output']>;
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
};

/** WorkOrderのカスタムフィールド(Int)の値 */
export type WorkOrderCustomFieldIntValue = {
  __typename?: 'WorkOrderCustomFieldIntValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type WorkOrderCustomFieldIntValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['Int']['input'];
};

/** WorkOrderCustomFieldOption */
export type WorkOrderCustomFieldOption = {
  __typename?: 'WorkOrderCustomFieldOption';
  dependencyAssets: Array<Scalars['Int']['output']>;
  id: Scalars['Float']['output'];
  value: Scalars['String']['output'];
};

/** WorkOrderCustomFieldOptionCategory */
export type WorkOrderCustomFieldOptionCategory = {
  __typename?: 'WorkOrderCustomFieldOptionCategory';
  name: Scalars['String']['output'];
  selectItems: Array<WorkOrderCustomFieldSelectItem>;
};

/** WorkOrderCustomFieldSelectConfig */
export type WorkOrderCustomFieldSelectConfig = {
  __typename?: 'WorkOrderCustomFieldSelectConfig';
  hasCategory: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  multiple: Scalars['Boolean']['output'];
};

/** WorkOrderCustomFieldSelectItem */
export type WorkOrderCustomFieldSelectItem = {
  __typename?: 'WorkOrderCustomFieldSelectItem';
  category?: Maybe<WorkOrderCustomFieldOptionCategory>;
  option?: Maybe<WorkOrderCustomFieldOption>;
};

/** WorkOrderのカスタムフィールド(Select)の値 */
export type WorkOrderCustomFieldSelectValue = {
  __typename?: 'WorkOrderCustomFieldSelectValue';
  categoryName?: Maybe<Scalars['String']['output']>;
  customFieldId: Scalars['Int']['output'];
  optionId: Scalars['Float']['output'];
  remark?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type WorkOrderCustomFieldSelectValueInput = {
  customFieldId: Scalars['Int']['input'];
  optionId: Scalars['Float']['input'];
};

/** WorkOrderCustomFieldTextConfig */
export type WorkOrderCustomFieldTextConfig = {
  __typename?: 'WorkOrderCustomFieldTextConfig';
  customFieldId: Scalars['Float']['output'];
  id: Scalars['Float']['output'];
  rows: Scalars['Int']['output'];
};

/** WorkOrderのカスタムフィールド(Text)の値 */
export type WorkOrderCustomFieldTextValue = {
  __typename?: 'WorkOrderCustomFieldTextValue';
  customFieldId: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export type WorkOrderCustomFieldTextValueInput = {
  customFieldId: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export const WorkOrderCustomFieldType = {
  Date: 'date',
  Datetime: 'datetime',
  File: 'file',
  Float: 'float',
  Int: 'int',
  Select: 'select',
  Text: 'text',
  User: 'user',
} as const;

export type WorkOrderCustomFieldType =
  (typeof WorkOrderCustomFieldType)[keyof typeof WorkOrderCustomFieldType];
/** WorkOrderのカスタムフィールドユーザーの値 */
export type WorkOrderCustomFieldUserValue = {
  __typename?: 'WorkOrderCustomFieldUserValue';
  customFieldId: Scalars['Int']['output'];
  user: DisplayUser;
};

export type WorkOrderCustomFieldUserValueInput = {
  customFieldId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type WorkOrderFilterCustomFieldInput = {
  customFieldId: Scalars['Int']['input'];
  optionIds: Array<Scalars['Int']['input']>;
};

export type WorkOrderFilterDateRangeInput = {
  from: Scalars['LocalDate']['input'];
  to: Scalars['LocalDate']['input'];
};

export type WorkOrderFilterIdsInput = {
  ids: Array<Scalars['Int']['input']>;
};

export type WorkOrderFilterInput = {
  asset?: InputMaybe<WorkOrderFilterIdsInput>;
  assignee?: InputMaybe<WorkOrderFilterStringIdsInput>;
  customField?: InputMaybe<WorkOrderFilterCustomFieldInput>;
  dueDate?: InputMaybe<WorkOrderFilterDateRangeInput>;
  group?: InputMaybe<WorkOrderFilterIdsInput>;
  product?: InputMaybe<WorkOrderFilterIdsInput>;
  stoppageReason?: InputMaybe<WorkOrderFilterIdsInput>;
  taskCreatedAt?: InputMaybe<WorkOrderFilterDateRangeInput>;
  taskStoppageStartAt?: InputMaybe<WorkOrderFilterDateRangeInput>;
  type: WorkOrderFilterType;
  workOrderId?: InputMaybe<WorkOrderFilterIdsInput>;
};

export type WorkOrderFilterStringIdsInput = {
  ids: Array<Scalars['String']['input']>;
};

export const WorkOrderFilterType = {
  Asset: 'Asset',
  Assignee: 'Assignee',
  CustomField: 'CustomField',
  DueDate: 'DueDate',
  Group: 'Group',
  Product: 'Product',
  StoppageReason: 'StoppageReason',
  TaskCreatedAt: 'TaskCreatedAt',
  TaskStoppageStartAt: 'TaskStoppageStartAt',
  WorkOrderId: 'WorkOrderId',
} as const;

export type WorkOrderFilterType =
  (typeof WorkOrderFilterType)[keyof typeof WorkOrderFilterType];
/** WorkOrderInputReferenceData */
export type WorkOrderInputReferenceData = {
  __typename?: 'WorkOrderInputReferenceData';
  assetId?: Maybe<Scalars['Int']['output']>;
  assignees: Array<DisplayUser>;
  checkLists: Array<CheckList>;
  customFieldDateValues: Array<WorkOrderCustomFieldDateValue>;
  customFieldDatetimeValues: Array<WorkOrderCustomFieldDatetimeValue>;
  customFieldFileValues: Array<WorkOrderCustomFieldFileValue>;
  customFieldFloatValues: Array<WorkOrderCustomFieldFloatValue>;
  customFieldIntValues: Array<WorkOrderCustomFieldIntValue>;
  customFieldSelectValues: Array<WorkOrderCustomFieldSelectValue>;
  customFieldTextValues: Array<WorkOrderCustomFieldTextValue>;
  customFieldUserValues: Array<WorkOrderCustomFieldUserValue>;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  parts: Array<WorkOrderPart>;
  priority: WorkOrderPriority;
  productId?: Maybe<Scalars['Int']['output']>;
  status: WorkOrderStatus;
  stoppage?: Maybe<WorkOrderStoppage>;
  stoppageReason?: Maybe<StoppageReason>;
  templateId?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** WorkOrderLink */
export type WorkOrderLink = {
  __typename?: 'WorkOrderLink';
  id: Scalars['Int']['output'];
  inbound: WorkOrder;
  inboundId: Scalars['Int']['output'];
  outbound: WorkOrder;
  outboundId: Scalars['Int']['output'];
};

/** WorkOrderNotificationSetting */
export type WorkOrderNotificationSetting = {
  __typename?: 'WorkOrderNotificationSetting';
  notifyEnabled: Scalars['Boolean']['output'];
  priorityThreshold: Scalars['String']['output'];
  workOrderAssignEnabled: Scalars['Boolean']['output'];
  workOrderAssignToGroupMemberEnabled: Scalars['Boolean']['output'];
  workOrderCommentCreateEnabled: Scalars['Boolean']['output'];
  workOrderCompleteEnabled: Scalars['Boolean']['output'];
  workOrderCreateEnabled: Scalars['Boolean']['output'];
};

export type WorkOrderNotificationSettingInput = {
  notifyEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  priorityThreshold?: InputMaybe<Scalars['String']['input']>;
  workOrderAssignEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  workOrderAssignToGroupMemberEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  workOrderCommentCreateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  workOrderCompleteEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  workOrderCreateEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** WorkOrderに紐づいてる部品 */
export type WorkOrderPart = {
  __typename?: 'WorkOrderPart';
  part: DisplayPart;
  quantity: Scalars['Float']['output'];
};

/** WorkOrderに紐づいて使われる部品 */
export type WorkOrderPartInput = {
  partId: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
};

export const WorkOrderPriority = {
  High: 'high',
  Low: 'low',
  Medium: 'medium',
  None: 'none',
} as const;

export type WorkOrderPriority =
  (typeof WorkOrderPriority)[keyof typeof WorkOrderPriority];
export const WorkOrderSortByType = {
  Asset: 'Asset',
  CreatedAt: 'CreatedAt',
} as const;

export type WorkOrderSortByType =
  (typeof WorkOrderSortByType)[keyof typeof WorkOrderSortByType];
export const WorkOrderStatus = {
  Done: 'done',
  InProgress: 'inProgress',
  OnHold: 'onHold',
  Open: 'open',
} as const;

export type WorkOrderStatus =
  (typeof WorkOrderStatus)[keyof typeof WorkOrderStatus];
/** WorkOrderの停止についての項目 */
export type WorkOrderStoppage = {
  __typename?: 'WorkOrderStoppage';
  endAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  startAt?: Maybe<Scalars['DateTime']['output']>;
};

/** WorkOrderの停止についての項目 */
export type WorkOrderStoppageInput = {
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** WorkOrderTemplate */
export type WorkOrderTemplate = {
  __typename?: 'WorkOrderTemplate';
  asset?: Maybe<Asset>;
  assetEnabled: Scalars['Boolean']['output'];
  assigneeEnabled: Scalars['Boolean']['output'];
  checkListEnabled: Scalars['Boolean']['output'];
  customFields: Array<WorkOrderCustomField>;
  defaultAssignees: Array<DisplayUser>;
  defaultCheckListTemplates: Array<CheckListTemplate>;
  /** @deprecated No longer used, delete later. */
  defaultGroups?: Maybe<Array<Group>>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionEnabled: Scalars['Boolean']['output'];
  dueEnabled: Scalars['Boolean']['output'];
  fieldOrders: Array<WorkOrderTemplateFieldOrder>;
  groupEnabled: Scalars['Boolean']['output'];
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  partEnabled: Scalars['Boolean']['output'];
  priorityEnabled: Scalars['Boolean']['output'];
  productEnabled: Scalars['Boolean']['output'];
  projectId: Scalars['Float']['output'];
  scheduleDueDates: Array<WorkOrderTemplateScheduleDueDate>;
  scheduleMonthlies: Array<WorkOrderTemplateScheduleMonthly>;
  scheduleMonthlyDayOfWeeks: Array<WorkOrderTemplateScheduleMonthlyDayOfWeek>;
  scheduleWeeklies: Array<WorkOrderTemplateScheduleWeekly>;
  stoppageEnabled: Scalars['Boolean']['output'];
  titleEnabled: Scalars['Boolean']['output'];
  useCheckList: Scalars['Boolean']['output'];
  useDefault: Scalars['Boolean']['output'];
  useRequest: Scalars['Boolean']['output'];
  useSchedule: Scalars['Boolean']['output'];
};

export type WorkOrderTemplateDefaultAssigneeInput = {
  userId: Scalars['String']['input'];
};

export type WorkOrderTemplateDefaultCheckListTemplate = {
  templateId: Scalars['Int']['input'];
};

/** WorkOrderTemplateFieldOrder */
export type WorkOrderTemplateFieldOrder = HasNullableWorkOrderCustomField & {
  __typename?: 'WorkOrderTemplateFieldOrder';
  customField?: Maybe<WorkOrderCustomField>;
  customFieldId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Float']['output'];
  order: Scalars['Int']['output'];
  requiredOnDone: Scalars['Boolean']['output'];
  templateId: Scalars['Int']['output'];
  type: WorkOrderTemplateFieldOrderType;
};

export type WorkOrderTemplateFieldOrderInput = {
  customFieldId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  order: Scalars['Float']['input'];
  requiredOnDone: Scalars['Boolean']['input'];
  type: WorkOrderTemplateFieldOrderType;
};

export const WorkOrderTemplateFieldOrderType = {
  Asset: 'asset',
  Assignee: 'assignee',
  CheckList: 'checkList',
  CustomField: 'customField',
  Description: 'description',
  Due: 'due',
  Group: 'group',
  Part: 'part',
  Priority: 'priority',
  Product: 'product',
  Stoppage: 'stoppage',
  StoppageReason: 'stoppageReason',
  Title: 'title',
} as const;

export type WorkOrderTemplateFieldOrderType =
  (typeof WorkOrderTemplateFieldOrderType)[keyof typeof WorkOrderTemplateFieldOrderType];
export type WorkOrderTemplateForCheckListInput = {
  assetEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  assetId?: InputMaybe<Scalars['Float']['input']>;
  assigneeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  dueEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  groupEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  partEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  priorityEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  productEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  stoppageEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  titleEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** WorkOrderTemplate */
export type WorkOrderTemplateForCheckListTemplate = {
  __typename?: 'WorkOrderTemplateForCheckListTemplate';
  asset?: Maybe<Asset>;
  assetEnabled: Scalars['Boolean']['output'];
  assigneeEnabled: Scalars['Boolean']['output'];
  checkListEnabled: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionEnabled: Scalars['Boolean']['output'];
  dueEnabled: Scalars['Boolean']['output'];
  fieldOrders: Array<WorkOrderTemplateFieldOrder>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  partEnabled: Scalars['Boolean']['output'];
  priorityEnabled: Scalars['Boolean']['output'];
  productEnabled: Scalars['Boolean']['output'];
  projectId: Scalars['Float']['output'];
  stoppageEnabled: Scalars['Boolean']['output'];
  titleEnabled: Scalars['Boolean']['output'];
  useCheckList: Scalars['Boolean']['output'];
  useSchedule: Scalars['Boolean']['output'];
};

/** WorkOrderTemplateScheduleDueDate */
export type WorkOrderTemplateScheduleDueDate = {
  __typename?: 'WorkOrderTemplateScheduleDueDate';
  date: Scalars['DateTime']['output'];
  templateId: Scalars['Float']['output'];
  workOrderCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  workOrderId?: Maybe<Scalars['Int']['output']>;
};

export type WorkOrderTemplateScheduleDueDateInput = {
  date: Scalars['DateTime']['input'];
};

/** WorkOrderTemplateScheduleMonthly */
export type WorkOrderTemplateScheduleMonthly = {
  __typename?: 'WorkOrderTemplateScheduleMonthly';
  datesCreated: Array<Scalars['DateTime']['output']>;
  day: Scalars['Int']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  interval: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
  templateId: Scalars['Int']['output'];
};

/** WorkOrderTemplateScheduleMonthlyDayOfWeek */
export type WorkOrderTemplateScheduleMonthlyDayOfWeek = {
  __typename?: 'WorkOrderTemplateScheduleMonthlyDayOfWeek';
  datesCreated: Array<Scalars['DateTime']['output']>;
  dayOfWeek: DayOfWeek;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  interval: Scalars['Int']['output'];
  nth: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
  templateId: Scalars['Int']['output'];
};

export type WorkOrderTemplateScheduleMonthlyDayOfWeekInput = {
  dayOfWeek: DayOfWeek;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  interval: Scalars['Int']['input'];
  nth: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type WorkOrderTemplateScheduleMonthlyInput = {
  day: Scalars['Int']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  interval: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
};

/** WorkOrderTemplateScheduleWeekly */
export type WorkOrderTemplateScheduleWeekly = {
  __typename?: 'WorkOrderTemplateScheduleWeekly';
  datesCreated: Array<Scalars['DateTime']['output']>;
  dayOfWeek: Scalars['String']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  interval: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
  templateId: Scalars['Int']['output'];
};

export type WorkOrderTemplateScheduleWeeklyInput = {
  dayOfWeek: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  interval: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
};

/** WorkOrder */
export type WorkOrdersWithPagination = {
  __typename?: 'WorkOrdersWithPagination';
  totalCount: Scalars['Float']['output'];
  workOrders: Array<WorkOrder>;
};

/** WorkflowApprovalStateApprover */
export type WorkflowApprovalStateApprover = {
  __typename?: 'WorkflowApprovalStateApprover';
  user: User;
  userId: Scalars['String']['output'];
};

export type WorkflowApprovalStateApproverInput = {
  userId: Scalars['String']['input'];
};

/** WorkflowApprovedEvent */
export type WorkflowApprovedEvent = WorkflowExecutionEvent & {
  __typename?: 'WorkflowApprovedEvent';
  approvedBy: User;
  approvedById: Scalars['String']['output'];
  approvedStateId: Scalars['WorkflowStateId']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  type: WorkflowExecutionEventType;
  workflow: WorkflowExecution;
  workflowExecutionId: Scalars['Int']['output'];
};

/** WorkflowCancelledEvent */
export type WorkflowCancelledEvent = WorkflowExecutionEvent & {
  __typename?: 'WorkflowCancelledEvent';
  cancelledBy: User;
  cancelledById: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  type: WorkflowExecutionEventType;
  workflow: WorkflowExecution;
  workflowExecutionId: Scalars['Int']['output'];
};

export type WorkflowDefinitionInput = {
  initialStateId: Scalars['WorkflowStateId']['input'];
  states: Array<WorkflowDefinitionStateInput>;
};

export type WorkflowDefinitionStateInput = {
  approvers?: InputMaybe<Array<WorkflowApprovalStateApproverInput>>;
  id: Scalars['WorkflowStateId']['input'];
  nextStateId?: InputMaybe<Scalars['WorkflowStateId']['input']>;
};

/** WorkflowExecution */
export type WorkflowExecution = {
  __typename?: 'WorkflowExecution';
  currentStateId: Scalars['WorkflowStateId']['output'];
  events: Array<WorkflowExecutionEvent>;
  /** SummaryテーブルのIDではなくexecutionIdになるので注意 */
  id: Scalars['Int']['output'];
  lastEvent?: Maybe<WorkflowExecutionEvent>;
  lastRejectedStateId?: Maybe<Scalars['WorkflowStateId']['output']>;
  lastSubmittedAt?: Maybe<Scalars['DateTime']['output']>;
  lastSubmittedBy?: Maybe<User>;
  lastSubmittedById?: Maybe<Scalars['String']['output']>;
  nextApproverUserIds: Array<Scalars['String']['output']>;
  nextApproverUsers: Array<User>;
  report?: Maybe<Report>;
  updatedAt: Scalars['DateTime']['output'];
  workflowTemplateVersion: WorkflowTemplateVersion;
  workflowTemplateVersionId: Scalars['Int']['output'];
};

/** WorkflowExecution */
export type WorkflowExecutionEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkflowExecutionEvent = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  type: WorkflowExecutionEventType;
  workflow: WorkflowExecution;
  workflowExecutionId: Scalars['Int']['output'];
};

export const WorkflowExecutionEventType = {
  Approved: 'Approved',
  Cancelled: 'Cancelled',
  Rejected: 'Rejected',
  Resubmitted: 'Resubmitted',
  Submitted: 'Submitted',
} as const;

export type WorkflowExecutionEventType =
  (typeof WorkflowExecutionEventType)[keyof typeof WorkflowExecutionEventType];
export const WorkflowExecutionsSearchOrderByType = {
  CreatedAt: 'CreatedAt',
  Status: 'Status',
} as const;

export type WorkflowExecutionsSearchOrderByType =
  (typeof WorkflowExecutionsSearchOrderByType)[keyof typeof WorkflowExecutionsSearchOrderByType];
export type WorkflowExecutionsSearchSortBy = {
  order: Order;
  type: WorkflowExecutionsSearchOrderByType;
};

/** WorkflowRejectedEvent */
export type WorkflowRejectedEvent = WorkflowExecutionEvent & {
  __typename?: 'WorkflowRejectedEvent';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  rejectedBy: User;
  rejectedById: Scalars['String']['output'];
  rejectedStateId: Scalars['WorkflowStateId']['output'];
  type: WorkflowExecutionEventType;
  workflow: WorkflowExecution;
  workflowExecutionId: Scalars['Int']['output'];
};

/** WorkflowResubmittedEvent */
export type WorkflowResubmittedEvent = WorkflowExecutionEvent & {
  __typename?: 'WorkflowResubmittedEvent';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  resubmittedBy: User;
  resubmittedById: Scalars['String']['output'];
  type: WorkflowExecutionEventType;
  workflow: WorkflowExecution;
  workflowExecutionId: Scalars['Int']['output'];
};

/** WorkflowState */
export type WorkflowState = {
  __typename?: 'WorkflowState';
  approvers?: Maybe<Array<WorkflowApprovalStateApprover>>;
  id: Scalars['WorkflowStateId']['output'];
  nextStateId?: Maybe<Scalars['WorkflowStateId']['output']>;
};

/** WorkflowSubmittedEvent */
export type WorkflowSubmittedEvent = WorkflowExecutionEvent & {
  __typename?: 'WorkflowSubmittedEvent';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  submittedBy: User;
  submittedById: Scalars['String']['output'];
  type: WorkflowExecutionEventType;
  workflow: WorkflowExecution;
  workflowExecutionId: Scalars['Int']['output'];
};

/** WorkflowTemplate */
export type WorkflowTemplate = {
  __typename?: 'WorkflowTemplate';
  id: Scalars['Int']['output'];
  latestVersion: WorkflowTemplateVersion;
};

/** WorkflowTemplateVersion */
export type WorkflowTemplateVersion = {
  __typename?: 'WorkflowTemplateVersion';
  id: Scalars['Int']['output'];
  initialStateId: Scalars['WorkflowStateId']['output'];
  states: Array<WorkflowState>;
};
